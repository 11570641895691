<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      style="z-index: 10"
      width="512"
      :height="$_windowMixin_contentHeight + $_windowMixin_getFooterHeight()"
    >
      <v-skeleton-loader
        v-if="loadingSent"
        type="list-item@20"
      />
      <v-list
        v-else
        nav
        dense
      >
        <v-list-item-group
          :value="campaignId"
          active-class="deep-purple--text text--accent-4"
        >
          <template
            v-for="(campaignItem, i) in sentCampaigns"
          >
            <v-divider
              v-if="i > 0 && !sentCampaigns[i-1].maxMailingScheduledDateLocal.isSame(campaignItem.maxMailingScheduledDateLocal, 'date')"
              :key="`${campaignItem.campaignId}-D`"
            />
            <v-subheader
              v-if="i === 0 || !sentCampaigns[i-1].maxMailingScheduledDateLocal.isSame(campaignItem.maxMailingScheduledDateLocal, 'date')"
              :key="`${campaignItem.campaignId}-T`"
              class="text-h5 font-weight-bold"
            >
              {{ campaignItem.maxMailingScheduledDateLocal | displayDateFormat3 }}
            </v-subheader>
            <v-list-item
              :key="campaignItem.campaignId"
              :value="campaignItem.campaignId"
            >
              <v-list-item-title
                @click="$router.push({ name: 'CampaignReport', params: { campaignId: campaignItem.campaignId } })"
              >
                <v-row>
                  <v-col cols="auto">
                    <v-chip
                      :color="campaignItem.campaignStatus === 3 ? 'green lighten-2' : 'accent'"
                    >
                      {{ campaignItem.maxMailingScheduledDateLocal | displayTimeFormat3 }}
                    </v-chip>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="align-self-center"
                  >
                    {{ campaignItem.campaignName }}
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <CampaignHeader
      ref="campaignHeader"
      disabled
      :campaign-id="campaignId"
    />
    <v-skeleton-loader
      v-if="reportLoading"
      type="table"
    />
    <v-card
      v-else-if="results"
      ref="html2Pdf2"
      class="campaign-report"
    >
      <v-card-text class="black--text font-weight-normal">
        <!--
        :pdf-content-width="`${$_windowMixin_getContentWidth() - 56}px`"
          @beforeDownload="beforePdfDownload($event)" -->
        <vue-html2pdf
          id="html2Pdf"
          ref="html2Pdf"
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="false"
          :filename="`${results.campaignMetaData.campaignNowwOrderNumber}_${results.campaignMetaData.campaignName}_CampaignReport`"
          :pdf-quality="2"
          :manual-pagination="true"
          :margin="[20, 20]"
          pdf-format="letter"
          pdf-orientation="landscape"
          pdf-content-width="100%"
          @beforeDownload="beforeDownload($event)"
        >
          <section
            slot="pdf-content"
            class="pdf-content"
          >
            <section class="pdf-header">
              <v-row
                no-gutters
                class="pb-4"
              >
                <v-col cols="8">
                  <span class="text-h4 font-weight-bold">Med-E-Mail Analytics</span>
                  <br>
                  <span class="text-h5 font-weight-light">Campaign Report Summary</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        class="ml-4"
                        icon
                        v-on="onTooltip"
                        @click="drawer = !drawer"
                      >
                        <v-icon
                          color="tertiary"
                        >
                          mdi-menu
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>View Other Sent Campaigns</span>
                  </v-tooltip>
                  <v-menu
                    v-model="pdfMenu"
                    right
                    x-offset
                    origin="right"
                    :close-on-click="false"
                    transition="slide-x-transition"
                  >
                    <template v-slot:activator="{ on: onMenu }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on: onTooltip }">
                          <v-btn
                            v-show="!pdfMenu"
                            icon
                            v-on="{ ...onMenu, ...onTooltip }"
                            @click="beforePdfDownload()"
                          >
                            <v-icon
                              color="success"
                            >
                              mdi-file-pdf-box
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Download Campaign Report PDF</span>
                      </v-tooltip>
                    </template>
                    <v-card>
                      <v-card-text class="pa-5">
                        <div>Formatting PDF for download...</div>
                        <div class="font-italic">
                          If the report looks too small, resize your browser window!
                        </div>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="tertiary"
                          @click="afterPdfDownload()"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="success"
                          @click="generatePdfReport()"
                        >
                          Download
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        v-on="onTooltip"
                        @click="downloadEntireReport()"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Campaign Report CSV</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="showAgeToggle"
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="toggleAgeData = !toggleAgeData"
                      >
                        <v-icon :color="toggleAgeData ? 'info' : 'tertiary'">
                          {{ toggleAgeData ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Toggle Age Data {{ toggleAgeData ? 'off' : 'on' }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="4">
                  <v-img
                    height="73"
                    contain
                    src="@/assets/mms-signature.jpg"
                  />
                </v-col>
              </v-row>
            </section>
            <v-divider class="divider1 mb-4" />
            <section class="summ-table">
              <v-card class="pa-2 ma-2">
                <v-row
                  no-gutters
                >
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-simple-table
                      id="headerTable1"
                      :v-if="false"
                      dense
                    >
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td
                              class="text-left"
                              width="175"
                            >
                              Client Name:
                            </td>
                            <td>{{ results.customerName }}</td>
                          </tr>
                          <tr>
                            <td
                              class="text-left"
                              width="175"
                            >
                              Campaign Name:
                            </td>
                            <td>{{ results.campaignName }}</td>
                          </tr>
                          <tr>
                            <td
                              class="text-left"
                              width="175"
                            >
                              NOWW Job Number:
                            </td>
                            <td>{{ results.campaignMetaData.campaignNowwOrderNumber }}</td>
                          </tr>
                          <tr>
                            <td
                              class="text-left"
                              width="175"
                            >
                              Lists:
                            </td>
                            <td>{{ results.lists.join(', ') }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-divider
                    vertical
                    class="mx-2 d-none d-sm-flex"
                  />
                  <v-row
                    v-if="pdfMenu"
                    style="margin-top: 20px"
                  >
                    <v-col
                      cols="12"
                      sm="10"
                    >
                      <v-simple-table
                        id="headerTable2"
                        :v-if="false"
                        dense
                      >
                        <template v-slot:default>
                          <tbody>
                            <tr
                              v-for="mailing in results.mailingsMetaData"
                              :key="mailing.id"
                            >
                              <td
                                class="text-left"
                                width="125"
                              >
                                {{ mailing.name }}
                              </td>
                              <td
                                class="text-left"
                                width="100"
                              >
                                Resend Type:
                              </td>
                              <td>{{ mailing.sequence > 1 ? $_resendType_display(mailing.resendType) : 'N/A' }}</td>
                              <td
                                class="text-left"
                                width="125"
                              >
                                {{ mailing.winningDistribution > 0 ? 'Split ' : '' }}Send Schedule:
                              </td>
                              <td>{{ $_global_parseAsUtc(mailing.scheduleDate) | displayDateTimeFormat }} - {{ $_global_parseAsUtc(mailing.scheduleDateEnd) | displayDateTimeFormat }}</td>
                              <td
                                v-if="mailing.winningDistribution > 0"
                                class="text-left"
                                width="175"
                              >
                                Winning Send Schedule:
                              </td>
                              <td
                                v-if="mailing.winningDistribution > 0"
                              >
                                {{ $_global_parseAsUtc(mailing.winningScheduleDate) | displayDateTimeFormat }} - {{ $_global_parseAsUtc(mailing.winningScheduleDateEnd) | displayDateTimeFormat }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-col
                    v-else
                    cols="12"
                    sm="7"
                  >
                    <v-simple-table
                      id="headerTable2"
                      :v-if="false"
                      dense
                    >
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="mailing in results.mailingsMetaData"
                            :key="mailing.id"
                          >
                            <td
                              class="text-left"
                              width="125"
                            >
                              {{ mailing.name }}
                            </td>
                            <td
                              class="text-left"
                              width="100"
                            >
                              Resend Type:
                            </td>
                            <td>{{ mailing.sequence > 1 ? $_resendType_display(mailing.resendType) : 'N/A' }}</td>
                            <td
                              class="text-left"
                              width="125"
                            >
                              {{ mailing.winningDistribution > 0 ? 'Split ' : '' }}Send Schedule:
                            </td>
                            <td>{{ $_global_parseAsUtc(mailing.scheduleDate) | displayDateTimeFormat }} - {{ moment($_global_parseAsUtc(mailing.scheduleDate)).add(1, 'd') | displayDateTimeFormat }}</td>
                            <td
                              v-if="mailing.winningDistribution > 0"
                              class="text-left"
                              width="175"
                            >
                              Winning Send Schedule:
                            </td>
                            <td
                              v-if="mailing.winningDistribution > 0"
                            >
                              {{ $_global_parseAsUtc(mailing.winningScheduleDate) | displayDateTimeFormat }} - {{ moment($_global_parseAsUtc(mailing.winningScheduleDate)).add(1, 'd') | displayDateTimeFormat }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card>
            </section>
            <v-divider class="my-4" />
            <v-card-subtitle
              v-if="(results.mailingsMetaData[results.mailingsMetaData.length - 1].winningDistribution > 0 && results.mailingsMetaData[results.mailingsMetaData.length - 1].winningScheduleDateEnd !== null && moment(results.mailingsMetaData[results.mailingsMetaData.length - 1].winningScheduleDateEnd).isSameOrAfter(moment(new Date().getTime()), 'day'))
                || (results.mailingsMetaData[results.mailingsMetaData.length - 1].winningDistribution <= 0 && results.mailingsMetaData[results.mailingsMetaData.length - 1].scheduleDateEnd !== null && moment(results.mailingsMetaData[results.mailingsMetaData.length - 1].scheduleDateEnd).isSameOrAfter(moment(new Date().getTime()), 'day'))"
              class="red--text font-weight-bold text-h5 pa-2 mb-4"
            >
              The email is still sending. Please check back later for an updated Campaign Report which will be completed on ({{ results.mailingsMetaData[results.mailingsMetaData.length - 1].winningDistribution > 0 ? moment($_global_parseAsUtc(results.mailingsMetaData[results.mailingsMetaData.length - 1].winningScheduleDateEnd)).add(1, 'd') : moment($_global_parseAsUtc(results.mailingsMetaData[results.mailingsMetaData.length - 1].scheduleDateEnd)).add(1, 'd') | displayDateFormat }}).
              <br>
              <span v-if="results.mailingsMetaData.length > 1">
                If you want to see metrics for individual mailings on this campaign, check out the Single Mailing Report(s):
                <br>
                <div
                  v-for="(mailing, index) in results.mailingsMetaData"
                  :key="mailing.id"
                >
                  <router-link
                    v-if="index !== results.mailingsMetaData.length - 1"
                    :to="{ name: 'SingleMailingReport', params: { campaignId: mailing.campaignId, mailingItemId: mailing.id } }"
                  >
                    {{ mailing.name }}
                  </router-link>
                </div>
              </span>
            </v-card-subtitle>
            <v-divider
              v-if="(results.mailingsMetaData[results.mailingsMetaData.length - 1].winningDistribution > 0 && results.mailingsMetaData[results.mailingsMetaData.length - 1].winningScheduleDateEnd !== null && moment(results.mailingsMetaData[results.mailingsMetaData.length - 1].winningScheduleDateEnd).isSameOrAfter(moment(new Date().getTime()), 'day'))
                || (results.mailingsMetaData[results.mailingsMetaData.length - 1].winningDistribution <= 0 && results.mailingsMetaData[results.mailingsMetaData.length - 1].scheduleDateEnd !== null && moment(results.mailingsMetaData[results.mailingsMetaData.length - 1].scheduleDateEnd).isSameOrAfter(moment(new Date().getTime()), 'day'))"
              class="my-4"
            />
            <div
              class="html2pdf__page-break"
            />
            <template v-if="results.deliverabilityMetricsTotals.totalAudience > 0">
              <section class="title-bar">
                <v-card-subtitle
                  class="grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text"
                  style="display: flex;"
                >
                  Deliverability Metrics
                  <v-spacer />
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportTable('deliverabilityMetrics', `${results.campaignMetaData.campaignNowwOrderNumber}-${results.campaignMetaData.campaignName.replace(/ /g, '')}-DeliverabilityMetrics`)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Deliverability Metrics</span>
                  </v-tooltip>
                </v-card-subtitle>
              </section>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  :lg="results.mailingsMetaData.length > 1 && pdfMenu ? '12': '6'"
                  sm="6"
                >
                  <section
                    class="delv-met"
                  >
                    <v-card
                      class="pa-2 ma-2"
                    >
                      <v-simple-table
                        id="deliverabilityMetrics"
                        dense
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                :style="[{ 'color': '#6d2c8a', 'text-align': 'right'}]"
                                class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                              />
                              <template v-if="results.mailingData.deliverabilityMetrics.length > 1">
                                <template v-for="(mailing, i) in results.mailingData.deliverabilityMetrics">
                                  <th
                                    :key="i"
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'right'}]"
                                    class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left table-border-bottom-bold delv-met-th-text"
                                  >
                                    {{ results.mailingsMetaData[i].name }}
                                  </th>
                                  <th
                                    :key="`${i}-rate`"
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'right'}]"
                                    class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                                  >
                                    {{ results.mailingsMetaData[i].name }} Rate
                                  </th>
                                </template>
                              </template>
                              <template v-if="!pdfMenu || results.mailingData.deliverabilityMetrics.length < 4">
                                <th
                                  :style="{ 'color': '#6d2c8a'}"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold delv-met-th-total"
                                >
                                  Total
                                </th>
                                <th
                                  :style="{ 'color': '#6d2c8a'}"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-total"
                                >
                                  Rate
                                </th>
                              </template>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- <tr>
                              <td
                                class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold"
                                width="200"
                              >
                                Target Audience
                              </td>
                              <template v-if="results.mailingData.deliverabilityMetrics.length > 1">
                                <template v-for="(deliverabilityMetrics, i) in results.mailingData.deliverabilityMetrics">
                                  <td
                                    :key="i"
                                    class="text-right table-border-left table-border-bottom-bold"
                                  >
                                    {{ deliverabilityMetrics.totalAudience | commaNumber }}
                                  </td>
                                  <td
                                    :key="`${i}-rate`"
                                    class="text-right table-border-bottom-bold"
                                  />
                                </template>
                              </template>
                              <td
                                class="text-right table-border-left-bold font-weight-bold table-border-bottom-bold"
                              >
                                {{ results.deliverabilityMetricsTotals.totalAudience | commaNumber }}
                              </td>
                              <td
                                class="text-right font-weight-bold table-border-bottom-bold"
                              />
                            </tr> -->
                            <!-- <tr>
                              <td
                                class="text-left primary--text font-weight-bold text-subtitle-2"
                                width="200"
                              >
                                Suppressed
                              </td>
                              <template v-if="results.mailingData.deliverabilityMetrics.length > 1">
                                <template v-for="(deliverabilityMetrics, i) in results.mailingData.deliverabilityMetrics">
                                  <td
                                    :key="i"
                                    class="text-right table-border-left"
                                  >
                                    {{ deliverabilityMetrics.suppressed | commaNumber }}
                                  </td>
                                  <td
                                    :key="`${i}-rate`"
                                    class="text-right"
                                  >
                                    {{ deliverabilityMetrics.suppressedRate | percent(2) }}
                                  </td>
                                </template>
                              </template>
                              <td
                                class="text-right table-border-left-bold font-weight-bold"
                              >
                                {{ results.deliverabilityMetricsTotals.suppressed | commaNumber }}
                              </td>
                              <td
                                class="text-right font-weight-bold"
                              >
                                {{ results.deliverabilityMetricsTotals.suppressedRate | percent(2) }}
                              </td>
                            </tr> -->
                            <tr>
                              <td
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                                width="200"
                              >
                                Sent
                              </td>
                              <template v-if="results.mailingData.deliverabilityMetrics.length > 1">
                                <template v-for="(deliverabilityMetrics, i) in results.mailingData.deliverabilityMetrics">
                                  <td
                                    :key="i"
                                    :style="[{ 'text-align': 'right' }]"
                                    class="text-right table-border-left table-border-bottom-bold"
                                  >
                                    <!-- <v-icon
                                    :color="$_splitMixin_splitColors[i]"
                                  >
                                    mdi-square-rounded
                                  </v-icon> -->
                                    {{ deliverabilityMetrics.sentMessages | commaNumber }}
                                  </td>
                                  <td
                                    :key="`${i}-rate`"
                                    :style="[{ 'text-align': 'right' }]"
                                    class="text-right table-border-bottom-bold"
                                  >
                                    {{ deliverabilityMetrics.sentRate | percent(2) }}
                                  </td>
                                </template>
                              </template>
                              <template v-if="!pdfMenu || results.mailingData.deliverabilityMetrics.length < 4">
                                <td
                                  class="text-right table-border-left-bold font-weight-bold table-border-bottom-bold delv-met-th-total"
                                >
                                  {{ results.deliverabilityMetricsTotals.sentMessages | commaNumber }}
                                </td>
                                <td
                                  class="text-right font-weight-bold table-border-bottom-bold delv-met-th-total"
                                >
                                  {{ results.deliverabilityMetricsTotals.sentRate | percent(2) }}
                                </td>
                              </template>
                            </tr>
                            <tr>
                              <td
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-left primary--text font-weight-bold text-subtitle-2 delv-met-th-text"
                                width="200"
                              >
                                Hard Bounce
                              </td>
                              <template v-if="results.mailingData.deliverabilityMetrics.length > 1">
                                <template v-for="(deliverabilityMetrics, i) in results.mailingData.deliverabilityMetrics">
                                  <td
                                    :key="i"
                                    :style="[{ 'text-align': 'right' }]"
                                    class="text-right table-border-left"
                                  >
                                    {{ deliverabilityMetrics.hardBounces | commaNumber }}
                                  </td>
                                  <td
                                    :key="`${i}-rate`"
                                    :style="[{ 'text-align': 'right' }]"
                                    class="text-right"
                                  >
                                    {{ deliverabilityMetrics.hardBounceRate | percent(2) }}
                                  </td>
                                </template>
                              </template>
                              <template v-if="!pdfMenu || results.mailingData.deliverabilityMetrics.length < 4">
                                <td
                                  class="text-right table-border-left-bold font-weight-bold delv-met-th-total"
                                >
                                  <!-- <v-icon
                                  :color="results.deliverabilityMetricsTotalsPieChart.datasets[0].backgroundColor[1]"
                                >
                                  mdi-square-rounded
                                </v-icon> -->
                                  {{ results.deliverabilityMetricsTotals.hardBounces | commaNumber }}
                                </td>
                                <td
                                  class="text-right font-weight-bold delv-met-th-total"
                                >
                                  {{ results.deliverabilityMetricsTotals.hardBounceRate | percent(2) }}
                                </td>
                              </template>
                            </tr>
                            <tr>
                              <td
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-left primary--text font-weight-bold text-subtitle-2 delv-met-th-text"
                                width="200"
                              >
                                Soft Bounce
                              </td>
                              <template v-if="results.mailingData.deliverabilityMetrics.length > 1">
                                <template v-for="(deliverabilityMetrics, i) in results.mailingData.deliverabilityMetrics">
                                  <td
                                    :key="i"
                                    :style="[{ 'text-align': 'right' }]"
                                    class="text-right table-border-left"
                                  >
                                    {{ deliverabilityMetrics.softBounces | commaNumber }}
                                  </td>
                                  <td
                                    :key="`${i}-rate`"
                                    :style="[{ 'text-align': 'right' }]"
                                    class="text-right"
                                  >
                                    {{ deliverabilityMetrics.softBounceRate | percent(2) }}
                                  </td>
                                </template>
                              </template>
                              <template v-if="!pdfMenu || results.mailingData.deliverabilityMetrics.length < 4">
                                <td
                                  class="text-right table-border-left-bold font-weight-bold delv-met-th-total"
                                >
                                  <!-- <v-icon
                                  :color="results.deliverabilityMetricsTotalsPieChart.datasets[0].backgroundColor[2]"
                                >
                                  mdi-square-rounded
                                </v-icon> -->
                                  {{ results.deliverabilityMetricsTotals.softBounces | commaNumber }}
                                </td>
                                <td
                                  class="text-right font-weight-bold delv-met-th-total"
                                >
                                  {{ results.deliverabilityMetricsTotals.softBounceRate | percent(2) }}
                                </td>
                              </template>
                            </tr>
                            <tr>
                              <td
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-left primary--text font-weight-bold text-subtitle-2 delv-met-th-text"
                                width="200"
                              >
                                Delivered Messages
                              </td>
                              <template v-if="results.mailingData.deliverabilityMetrics.length > 1">
                                <template v-for="(deliverabilityMetrics, i) in results.mailingData.deliverabilityMetrics">
                                  <td
                                    :key="i"
                                    :style="[{ 'text-align': 'right' }]"
                                    class="text-right table-border-left"
                                  >
                                    {{ deliverabilityMetrics.deliveredMessages | commaNumber }}
                                  </td>
                                  <td
                                    :key="`${i}-rate`"
                                    :style="[{ 'text-align': 'right' }]"
                                    class="text-right"
                                  >
                                    {{ deliverabilityMetrics.deliveredRate | percent(2) }}
                                  </td>
                                </template>
                              </template>
                              <template v-if="!pdfMenu || results.mailingData.deliverabilityMetrics.length < 4">
                                <td
                                  class="text-right table-border-left-bold font-weight-bold delv-met-th-total"
                                >
                                  {{ results.deliverabilityMetricsTotals.deliveredMessages | commaNumber }}
                                </td>
                                <td
                                  class="text-right font-weight-bold delv-met-th-total"
                                >
                                  {{ results.deliverabilityMetricsTotals.deliveredRate | percent(2) }}
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </section>
                  <template v-if="pdfMenu && results.mailingData.deliverabilityMetrics.length >= 4">
                    <section
                      :style="[{ 'margin-top': '20px', 'margin-bottom': '20px' }]"
                      class="delv-met"
                    >
                      <v-col
                        style="display: inline-table"
                        cols="12"
                        sm="6"
                      >
                        <tr>
                          <th
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                          />
                          <th
                            :style="[
                              { 'color': '#6d2c8a',
                                'text-align': 'right',
                                'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'
                              }]"
                            class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold delv-met-th-total"
                          >
                            Total
                          </th>
                          <th
                            :style="[
                              { 'color': '#6d2c8a',
                                'text-align': 'right',
                                'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'
                              }]"
                            class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-total"
                          >
                            Rate
                          </th>
                        </tr>
                        <tr>
                          <td
                            :style="[
                              { 'color': '#6d2c8a',
                                'text-align': 'left',
                                'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'
                              }]"
                            class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                            width="200"
                          >
                            Sent
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right table-border-left-bold font-weight-bold table-border-bottom-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.sentMessages | commaNumber }}
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right font-weight-bold table-border-bottom-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.sentRate | percent(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            :style="[
                              { 'color': '#6d2c8a',
                                'text-align': 'left',
                                'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'
                              }]"
                            class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                            width="200"
                          >
                            Hard Bounce
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right table-border-left-bold font-weight-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.hardBounces | commaNumber }}
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right font-weight-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.hardBounceRate | percent(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            :style="[
                              { 'color': '#6d2c8a',
                                'text-align': 'left',
                                'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'
                              }]"
                            class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                            width="200"
                          >
                            Soft Bounce
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right table-border-left-bold font-weight-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.softBounces | commaNumber }}
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right font-weight-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.softBounceRate | percent(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            :style="[
                              { 'color': '#6d2c8a',
                                'text-align': 'left',
                                'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'
                              }]"
                            class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                            width="200"
                          >
                            Delivered Messages
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right table-border-left-bold font-weight-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.deliveredMessages | commaNumber }}
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right font-weight-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.deliveredRate | percent(2) }}
                          </td>
                        </tr>
                      </v-col>
                    </section>
                  </template>
                </v-col>
                <v-col
                  cols="12"
                  :sm="results.mailingsMetaData.length > 1 && !pdfMenu ? '3' : '6'"
                >
                  <v-card class="pa-2 ma-2">
                    <pie-chart
                      :chart-data="results.deliverabilityMetricsTotalsPieChart"
                      :options="getChartOptionsPie(`Total Deliverability`)"
                      :height="224"
                      class="pb-2"
                    />
                  </v-card>
                </v-col>
                <v-col
                  v-if="results.mailingsMetaData.length > 1"
                  cols="12"
                  :lg="pdfMenu ? '6': '3'"
                  sm="3"
                >
                  <v-card class="pa-2 ma-2">
                    <pie-chart
                      :chart-data="results.sentCountPieChart"
                      :options="getChartOptionsPie(`Sent Count`)"
                      :height="224"
                      class="pb-2"
                    />
                  </v-card>
                </v-col>
              </v-row>
              <v-divider
                v-if="!pdfMenu"
                class="my-4"
              />
              <div class="html2pdf__page-break" />
              <section class="title-bar">
                <v-card-subtitle
                  class="grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text"
                  style="display: flex;"
                >
                  Engagement Summary
                  <v-spacer />
                  <!-- <v-tooltip top>

                  <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                      v-show="!pdfMenu"
                      icon
                      color="white"
                      v-on="onTooltip"
                      @click="$_downloadFileMixin_downloadReportTable('engagementSummary', `${results.campaignMetaData.campaignNowwOrderNumber}-${results.campaignMetaData.campaignName.replace(/ /g, '')}-EngagementSummary`)"
                    >
                      <v-icon color="green lighten-3">
                        mdi-file-download
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Download Engagement Summary</span>
                </v-tooltip> -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportTable('engagementSummaryByDay', `${results.campaignMetaData.campaignNowwOrderNumber}-${results.campaignMetaData.campaignName.replace(/ /g, '')}-EngagementSummaryByDay`)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Engagement Summary by Day</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="toggleGrossData = !toggleGrossData"
                      >
                        <v-icon :color="toggleGrossData ? 'light-blue lighten-4' : 'white'">
                          {{ toggleGrossData ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Toggle Gross/Opt Out Data {{ toggleGrossData ? 'off' : 'on' }}</span>
                  </v-tooltip>
                </v-card-subtitle>
              </section>
              <v-row
                no-gutters
              >
                <v-col
                  cols="12"
                >
                  <section class="dist-eng-summ">
                    <v-card class="pa-2 ma-2 text-center">
                      <v-row
                        no-gutters
                        justify="center"
                      >
                        <v-col
                          cols="12"
                        >
                          <div class="text-h5 font-weight-bold pa-2 dist-eng-summ-title">
                            Distinct Engagement Summary
                          </div>
                          <div class="text-h5 pb-2">
                            You delivered messages to <span class="green--text font-weight-bold dist-eng-summ-green">{{ results.distinctMetrics.distinctReceived | commaNumber }}</span> individuals in your campaign.
                          </div>
                          <div
                            class="text-h5 pb-2 text-center"
                          >
                            Of those delivered messages...
                            <div>
                              Your campaign engaged with <span class="green--text font-weight-bold dist-eng-summ-green">{{ results.distinctMetrics.distinctOpens | commaNumber }} ({{ results.distinctMetrics.distinctOpenRate }}%)</span> individuals!
                            </div>
                            <div>
                              You got clicks from <span class="green--text font-weight-bold dist-eng-summ-green">{{ results.distinctMetrics.distinctClicks | commaNumber }} ({{ results.distinctMetrics.distinctClickRate }}%)</span> individuals!
                            </div>
                            <div v-show="toggleGrossData">
                              <span class="grey--text font-weight-bold dist-eng-summ-gray">{{ results.distinctMetrics.distinctOptOuts | commaNumber }} ({{ results.distinctMetrics.distinctOptOutRate }}%)</span> individuals opted out of your emails.
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </section>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-card class="pa-2 ma-2 dist-eng-charts">
                    <bar-chart
                      :chart-data="results.clickToOpenChart"
                      :options="getChartOptionsBarPercent(`Unique Click to Open %`, '', '')"
                      :height="400"
                    />
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-card class="pa-2 ma-2 dist-eng-charts">
                    <line-chart
                      :chart-data="engagementMetricsData"
                      :options="getChartOptionsLine(`Clicks/Opens`)"
                    />
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-divider class="my-4" />
                  <section class="eng-summ-table">
                    <v-card class="pa-2 ma-2">
                      <v-simple-table
                        id="engagementSummaryByDay"
                        dense
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-left primary--text font-weight-bold text-subtitle-2 eng-summ-table-th"
                                :class="{ 'table-border-bottom-bold': results.mailingsMetaData.length <= 1 }"
                              >
                              </th>
                              <th
                                v-for="(metric, index) in engagementMetricsComputed"
                                :key="index"
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold eng-summ-table-th"
                                :class="{ 'table-border-bottom-bold': results.mailingsMetaData.length <= 1 }"
                                :colspan="getEngagementHeaderColspan()"
                              >
                                <v-icon
                                  v-if="pdfMenu && results.mailingsMetaData.length === 1"
                                  :color="engagementMetricsData.datasets[index].backgroundColor"
                                >
                                  mdi-square-rounded
                                </v-icon>
                                {{ metric }}
                              </th>
                            </tr>
                            <tr v-if="results.mailingsMetaData.length > 1">
                              <th
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-left primary--text font-weight-bold text-subtitle-2 eng-summ-table-th"
                              >
                                Date
                              </th>
                              <template v-for="(metrics, n) in engagementMetricsComputed">
                                <template v-for="(mailing, i) in results.mailingData.engagementSummary">
                                  <th
                                    :key="`${n}-${i}`"
                                    :style="{ 'color': '#6d2c8a'}"
                                    class="text-right primary--text font-weight-bold text-subtitle-2 eng-summ-table-th"
                                    :class="{ 'table-border-left-bold' : i === 0 }"
                                  >
                                    <v-icon
                                      v-if="!pdfMenu"
                                      :color="engagementMetricsData.datasets[i * engagementMetricsComputed.length + n].backgroundColor"
                                    >
                                      mdi-square-rounded
                                    </v-icon>
                                    {{ results.mailingsMetaData[i].name }}
                                  </th>
                                </template>
                                <th
                                  :key="`t-${n}`"
                                  :style="{ 'color': '#6d2c8a'}"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left eng-summ-table-th"
                                >
                                  Total
                                </th>
                              </template>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(label, i) in engagementMetricsData.labels"
                              :key="`${label}`"
                              style="display: none;"
                            >
                              <td
                                class="text-left primary--text font-weight-bold text-subtitle-2"
                              >
                                {{ label }}
                              </td>
                              <template
                                v-for="(metric) in engagementMetricsComputed"
                              >
                                <td
                                  v-for="(mailing, mailingIndex) in results.mailingsMetaData"
                                  :key="`${metric}-${mailing.name}-${i}`"
                                  class="text-right"
                                  :class="{ 'table-border-left-bold' : mailingIndex === 0 }"
                                >
                                  {{ engagementMetricsData.datasets.find(x => x.label === `${mailing.name} - ${metric}`).data[i] | commaNumber }}
                                </td>
                                <td
                                  v-if="results.mailingsMetaData.length > 1"
                                  :key="`t-${metric}`"
                                  class="text-right font-weight-bold table-border-left"
                                >
                                  {{ getTotalRowForEngagement(i, metric) | commaNumber }}
                                </td>
                              </template>
                            </tr>
                            <tr>
                              <td
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-left primary--text font-weight-bold text-subtitle-2 table-border-top-bold eng-summ-table-row-top-border"
                              >
                                Total
                              </td>
                              <template
                                v-for="(metric) in engagementMetricsComputed"
                              >
                                <td
                                  v-for="(mailing, mailingIndex) in results.mailingsMetaData"
                                  :key="`t-${metric}-${mailing.name}`"
                                  class="text-right table-border-top-bold font-weight-bold eng-summ-table-row-top-border"
                                  :class="{ 'table-border-left-bold' : mailingIndex === 0 }"
                                >
                                  {{ engagementMetricsData.datasets.find(x => x.label === `${mailing.name} - ${metric}`).data.reduce((prev, next) => prev + next) | commaNumber }}
                                </td>
                                <td
                                  v-if="results.mailingsMetaData.length > 1"
                                  :key="`tt-${metric}`"
                                  class="text-right table-border-top-bold font-weight-bold table-border-left eng-summ-table-head-total"
                                >
                                  {{ getTotalTableForEngagement(metric) | commaNumber }}
                                </td>
                              </template>
                            </tr>
                            <tr>
                              <td
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-left primary--text font-weight-bold text-subtitle-2 eng-summ-table-th"
                              >
                                %
                              </td>
                              <template v-if="results.mailingsMetaData.length > 1 && pdfMenu">
                                <template
                                  v-for="(metric) in engagementMetricsComputed"
                                >
                                  <td
                                    v-for="(mailing, mailingIndex) in results.mailingsMetaData"
                                    :key="`%-${metric}-${mailingIndex}`"
                                    class="text-right font-weight-bold eng-summ-table-td-total"
                                    :class="{ 'table-border-left-bold' : mailingIndex === 0 }"
                                  >
                                    {{ getEngagementPercent(engagementMetricsData.datasets, `${mailing.name} - ${metric}`, results.mailingData.engagementSummary[mailingIndex].deliveredMessages) | percent(0) }}
                                  </td>
                                  <td
                                    v-if="results.mailingsMetaData.length > 1"
                                    :key="`tt-${metric}`"
                                    class="text-right font-weight-bold table-border-left eng-summ-table-bottom-total"
                                  >
                                    {{ getTotalTableForEngagementPercent(metric) | percent(0) }}
                                  </td>
                                </template>
                              </template>
                              <template v-else>
                                <template
                                  v-for="(metric) in engagementMetricsComputed"
                                >
                                  <td
                                    v-for="(mailing, mailingIndex) in results.mailingsMetaData"
                                    :key="`%-${metric}-${mailingIndex}`"
                                    class="text-right font-weight-bold"
                                    :class="{ 'table-border-left-bold' : mailingIndex === 0 }"
                                  >
                                    {{ getEngagementPercent(engagementMetricsData.datasets, `${mailing.name} - ${metric}`, results.mailingData.engagementSummary[mailingIndex].deliveredMessages) | percent(2) }}
                                  </td>
                                  <td
                                    v-if="results.mailingsMetaData.length > 1"
                                    :key="`tt-${metric}`"
                                    class="text-right font-weight-bold table-border-left"
                                  >
                                    {{ getTotalTableForEngagementPercent(metric) | percent(2) }}
                                  </td>
                                </template>
                              </template>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </section>
                </v-col>
              </v-row>
              <v-divider class="my-4" />
              <div class="html2pdf__page-break" />
              <section class="title-bar">
                <v-card-subtitle
                  class="grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text"
                  style="display: flex;"
                >
                  Reading Engagement
                  <v-spacer />
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportChart(results.readingEnvironmentsChart, '%', `${results.campaignMetaData.campaignNowwOrderNumber}-${results.campaignMetaData.campaignName.replace(/ /g, '')}-ReadingEnvironments`)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Reading Environments</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportChart(results.emailClientDeviceSummary, '%', `${results.campaignMetaData.campaignNowwOrderNumber}-${results.campaignMetaData.campaignName.replace(/ /g, '')}-EmailClientsUsed`)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Email Clients Used</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportChart(results.viewingEngagementTotalSummary, '%', `${results.campaignMetaData.campaignNowwOrderNumber}-${results.campaignMetaData.campaignName.replace(/ /g, '')}-TimeSpentViewing`)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Time Spent Viewing</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="results.mailingsMetaData.length > 1"
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportTable('readingEngagement', `${results.campaignMetaData.campaignNowwOrderNumber}-${results.campaignMetaData.campaignName.replace(/ /g, '')}-TimeSpentViewingTable`)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Time Spent Viewing Table</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="showAgeData"
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportChart(results.ageEngagementChart, '%', `${results.campaignMetaData.campaignNowwOrderNumber}-${results.campaignMetaData.campaignName.replace(/ /g, '')}-AgeEngagement`, true)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Age Engagement</span>
                  </v-tooltip>
                </v-card-subtitle>
              </section>
              <section class="reading-eng">
                <v-card class="pa-2 ma-2 dist-eng-charts">
                  <v-row no-gutters>
                    <!-- Commenting Reading Engagement description - ticket #1499 -->
                    <!-- <v-col
                      cols="12"
                      class="justify-center"
                    >
                      <v-simple-table
                        dense
                        class="no-borders no-hover"
                      >
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td
                                :colspan="getNumberOfSendsPresented()"
                                class="text-h5 text-center reading-eng-desc"
                              >
                                Percentage of your readers that spent longer than 2 seconds looking at your email:
                              </td>
                            </tr>
                            <tr>
                              <td
                                v-for="(val, i) in results.mailingData.detailedViewingEngagement"
                                :key="i"
                                :class="`text-h4 text-center pr-4 ${$_splitMixin_colorToTextClass($_splitMixin_splitColors[i])}`"
                                class="reading-eng-summ"
                                :style="{'color': `${$_splitMixin_pdfSplitColors[i]}`}"
                              >
                                {{ results.mailingsMetaData[i].name }}: {{ val | percent(0) }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-divider
                        v-if="!pdfMenu"
                        class="my-4"
                      />
                    </v-col> -->
                    <v-col
                      cols="12"
                    >
                      <line-chart
                        :chart-data="results.mailingData.viewingEngagementSummary"
                        :options="getChartOptionsLinePercent(`Total Viewing Engagement`, `% of Open Audience`, `Time spent viewing (seconds)`)"
                        class="pb-2"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </section>
              <v-divider class="my-4" />
              <div
                class="html2pdf__page-break"
              />
              <v-row
                no-gutters
              >
                <v-col
                  cols="12"
                  :md="pdfMenu ? '6': '4'"
                >
                  <v-card
                    class="pa-2 ma-2 dist-eng-charts"
                  >
                    <bar-chart
                      :chart-data="results.readingEnvironmentsChart"
                      :options="getChartOptionsBarPercent(`Reading Environments`, '% of Gross Opens', 'Environment')"
                      :height="350"
                    />
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  :md="pdfMenu ? '6': '4'"
                >
                  <v-card class="pa-2 ma-2">
                    <bar-chart
                      :chart-data="results.emailClientDeviceSummary"
                      :options="getChartOptionsBarPercent(`Email Clients Used`, '% of Gross Opens', 'Email Client')"
                      :height="350"
                    />
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  :md="pdfMenu ? '6': '4'"
                >
                  <v-card
                    class="pa-2 ma-2 dist-eng-charts"
                    :style="[pdfMenu ? {'display': 'inline-table'} : '']"
                  >
                    <bar-chart
                      :chart-data="results.viewingEngagementTotalSummary"
                      :options="getChartOptionsBarPercent(`Time Spent Viewing`, '% of Gross Opens', 'Read Duration')"
                      :height="results.mailingsMetaData.length > 1 ? 218 : 350"
                    />
                    <v-simple-table
                      v-if="results.mailingsMetaData.length > 1"
                      id="readingEngagement"
                      dense
                      style="flex: auto;"
                      class="px-8"
                      :class="{ 'pt-4': pdfMenu }"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold reading-eng-th-text"
                            />
                            <th
                              v-for="(mailing, i) in results.mailingData.litmusReadTable"
                              :key="i"
                              :style="{ 'color': '#6d2c8a'}"
                              class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold reading-eng-th-text"
                              :class="{ 'table-border-left-bold': i === 0 }"
                            >
                              {{ results.mailingsMetaData[i].name }}
                            </th>
                            <th
                              :style="{ 'color': '#6d2c8a'}"
                              class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold reading-eng-th-text"
                            >
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(metric, index) in readTimeMetrics"
                            :key="index"
                          >
                            <td
                              :style="{ 'color': '#6d2c8a'}"
                              class="text-left primary--text font-weight-bold text-subtitle-2 reading-eng-trtitle-text"
                            >
                              <v-icon
                                :color="results.viewingEngagementTotalSummary.datasets[0].backgroundColor[index]"
                              >
                                mdi-square-rounded
                              </v-icon>
                              {{ metric.display }}
                            </td>
                            <template
                              v-for="(litmusRow, litmusIndex) in results.mailingData.litmusReadTable"
                            >
                              <td
                                :key="`${litmusIndex}`"
                                class="text-right reading-eng-td-row"
                                :class="{ 'table-border-left-bold': litmusIndex === 0 }"
                              >
                                {{ litmusRow.find(x => x.device == 'Total')[metric.rate] | percent }}
                              </td>
                            </template>
                            <template
                              v-for="(litmusRow, litmusIndex) in results.litmusReadTable"
                            >
                              <td
                                :key="`t-${litmusIndex}`"
                                class="text-right font-weight-bold table-border-left-bold reading-eng-td-total"
                              >
                                {{ litmusRow[metric.value] | percent }}
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-row>
              <div
                v-if="showAgeData"
                class="html2pdf__page-break"
              />
              <v-row no-gutters>
                <v-col
                  v-if="showAgeData"
                  cols="12"
                >
                  <v-card class="pa-2 ma-2 dist-eng-charts">
                    <bar-chart
                      :chart-data="results.ageEngagementChart"
                      :options="getChartOptionsBarPercent2(`Age Engagement`, `%`, `Age Range`)"
                      class="pb-2"
                    />
                  </v-card>
                </v-col>
              </v-row>
              <v-divider class="my-4" />
            </template>
          </section>
        </vue-html2pdf>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import { inputDateFormat } from '@/shared/constants'
import { mapGetters } from 'vuex'
import claims from '@/shared/models/auth/claims'
import { TableCSVExporter } from '@/shared/models/mailing'
import VueHtml2pdf from 'vue-html2pdf'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import moment from 'moment'
import { splitMixin } from '@/shared/mixins/mailing'
import { enumsMixin, downloadFileMixin, windowMixin } from '@/shared/mixins/general'
import { mailingService, azBlobService } from '@/shared/services'
// import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'

export default {
  name: 'CampaignReport',

  components: {
    CampaignHeader: () => import('@/views/pages/components/mailing/CampaignHeader'),
    LineChart: () => import('@/views/pages/components/charts/LineChart'),
    BarChart: () => import('@/views/pages/components/charts/BarChart'),
    PieChart: () => import('@/views/pages/components/charts/PieChart'),
    VueHtml2pdf
  },

  mixins: [splitMixin, enumsMixin, downloadFileMixin, windowMixin],

  props: {
    campaignId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      reportLoading: false,
      pdfMenu: false,
      engagementMetrics: [
        'Gross Clicks',
        'Unique Clicks',
        'Gross Opens',
        'Unique Opens',
        'Opt Outs'
      ],
      engagementMetricsOff: [
        'Unique Clicks',
        'Unique Opens'
      ],
      readTimeMetrics: [
        { display: 'Glanced (0-2s)', value: 'glanced', rate: 'glancedRate' },
        { display: 'Skimmed (3-7s)', value: 'skimmed', rate: 'skimmedRate' },
        { display: 'Read (8s+)', value: 'read', rate: 'readRate' }
      ],
      results: null,
      drawer: false,
      loadingSent: false,
      sentCampaigns: [],
      toggleAgeData: false,
      toggleGrossData: false
    }
  },

  watch: {
    '$route': function () {
      this.getCampaignReport()
    },
    drawer: function (newValue, oldValue) {
      if (newValue) {
        this.$vuetify.goTo(0)
      }
    }
  },

  created () {
    this.afterPdfDownload()
    this.getCampaignReport()
    this.loadSentCampaigns()
  },

  computed: {
    ...mapGetters('auth', ['hasClaimKV']),
    showAgeData () {
      return this.hasClaimKV(claims.R_AD) && (!this.showAgeToggle || this.toggleAgeData)
    },
    showAgeToggle () {
      return this.hasClaimKV(claims.MMS_TRA)
    },
    engagementMetricsComputed () {
      return this.toggleGrossData ? this.engagementMetrics : this.engagementMetricsOff
    },
    engagementMetricsData () {
      if (!this.results || !this.results.mailingData || !this.results.mailingData.engagementSummaryChart) return {}
      return {
        ...this.results.mailingData.engagementSummaryChart,
        datasets: this.results.mailingData.engagementSummaryChart.datasets.filter(x => this.engagementMetricsComputed.some(metric => x.label.includes(metric)))
      }
    },
    tableElementsList () {
      let arr = []
      arr.push(document.getElementById('deliverabilityMetrics'))
      arr.push(document.getElementById('engagementSummaryByDay'))
      if (this.results.mailingsMetaData.length > 1) {
        arr.push(document.getElementById('readingEngagement'))
      }
      return arr
    }
  },

  methods: {
    async loadSentCampaigns () {
      this.loadingSent = true
      await mailingService.getCompletedCampaignsList(this.$store.getters['simulatedCustomerCrmId']())
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.sentCampaigns = resp.map((x, index) => (
              {
                index,
                ...x,
                modifiedOnLocal: x.modifiedOn ? moment(this.$_global_parseAsUtc(x.modifiedOn)).local() : null,
                maxMailingScheduledDateLocal: x.maxMailingScheduledDate ? moment(this.$_global_parseAsUtc(x.maxMailingScheduledDate)).local() : null
                // defaulScheduleDateLocal: x.defaulScheduleDate ? moment(this.$_global_parseAsUtc(x.defaulScheduleDate)).local() : null,
                // winningScheduleDateLocal: x.winningScheduleDate ? moment(this.$_global_parseAsUtc(x.winningScheduleDate)).local() : null,
                // sentOnLocal: x.sentOn ? moment(this.$_global_parseAsUtc(x.sentOn)).local() : null
              }
            ))
          }
        })
        .finally(() => {
          this.loadingSent = false
        })
    },
    getTimezoneOffset () {
      var dateString = new Date()
      return dateString.getTimezoneOffset()
    },
    moment (date) {
      return moment(date)
    },
    async getCampaignReport () {
      this.$vuetify.goTo(0)
      this.results = null
      this.reportLoading = true
      await mailingService.getCampaignReport({ campaignId: this.campaignId, clientTimeZone: this.getTimezoneOffset() })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.results = resp
          }
        })
        .finally(() => {
          this.reportLoading = false
        })
    },
    getChartOptionsLine (title) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: title
        },
        legend: {
          position: 'right',
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
            fontSize: 14
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }],
          xAxes: [{
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }]
        }
        // plugins: {
        //   datalabels: {
        //     display: false,
        //     clamp: true,
        //     anchor: 'end',
        //     align: 'top',
        //     rotation: -30,
        //     formatter: function (value, context) {
        //       return `${parseFloat(value).toFixed(2)}`
        //     },
        //     font: {
        //       family: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
        //       weight: 'bold'
        //     }
        //   }
        // }
      }
    },
    getChartOptionsLinePercent (title, yAxisLabel, xAxisLabel) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: title
        },
        legend: {
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
            fontSize: 14
          }
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: yAxisLabel
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
              beginAtZero: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              // Include a '%' sign in the ticks
              callback: function (value, index, values) {
                return `${value}%`
              }
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: xAxisLabel
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }]
        }
        // plugins: {
        //   datalabels: {
        //     clamp: true,
        //     anchor: 'end',
        //     align: 'top',
        //     rotation: -30,
        //     formatter: function (value, context) {
        //       return `${parseFloat(value).toFixed(2)}%`
        //     },
        //     font: {
        //       family: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
        //       weight: 'bold'
        //     }
        //   }
        // }
      }
    },
    getChartOptionsBar (title) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: title
        },
        legend: {
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
            fontSize: 14
          }
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }]
        }
        // plugins: {
        //   datalabels: {
        //     clamp: true,
        //     anchor: 'end',
        //     align: 'top',
        //     rotation: -30,
        //     formatter: function (value, context) {
        //       return `${parseFloat(value).toFixed(2)}`
        //     },
        //     font: {
        //       family: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
        //       weight: 'bold'
        //     }
        //   }
        // }
      }
    },
    getChartOptionsBarPercent (title, yAxisLabel, xAxisLabel) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: title
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: yAxisLabel,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: yAxisLabel
            },
            ticks: {
              beginAtZero: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              // max: max,
              // Include a '%' sign in the ticks
              callback: function (value, index, values) {
                return `${value}%`
              }
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: xAxisLabel,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: xAxisLabel
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }]
        }
        // plugins: {
        //   datalabels: {
        //     clamp: true,
        //     anchor: 'end',
        //     align: 'top',
        //     rotation: -30,
        //     formatter: function (value, context) {
        //       return `${parseFloat(value).toFixed(2)}%`
        //     },
        //     font: {
        //       family: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
        //       weight: 'bold'
        //     }
        //   }
        // }
      }
    },
    getChartOptionsBarPercent2 (title, yAxisLabel, xAxisLabel) {
      var bar = this.getChartOptionsBarPercent(title, yAxisLabel, xAxisLabel)
      return {
        ...bar,
        legend: {
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
            fontSize: 14
          }
        }
      }
    },
    getChartOptionsPie (title, legendPosition = 'right') {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: title
        },
        legend: {
          position: legendPosition,
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
          }
        }
        // plugins: {
        //   datalabels: {
        //     display: false,
        //     clamp: true,
        //     anchor: 'end',
        //     align: 'top',
        //     rotation: -30,
        //     font: {
        //       family: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
        //       weight: 'bold'
        //     }
        //   }
        // }
      }
    },
    getChartOptionsPie2 (title, legendPosition = 'top') {
      var pie = this.getChartOptionsPie(title, legendPosition)
      return {
        ...pie,
        legend: {
          ...pie.legend,
          labels: {
            ...pie.legend.labels,
            fontStyle: 'bold',
            fontSize: 18
          }
        }
      }
    },
    // getMaxYAxisForChart (chart) {
    //   var max = 0
    //   if (!chart || !chart.datasets) return 100
    //   chart.datasets.forEach(x => {
    //     max = Math.max(max, Math.max(...x.data))
    //   })
    //   return Math.ceil(max * 1.2)
    // },
    getEngagementPercent (data, label, delivered) {
      var sum = data.find(x => x.label === label).data.reduce((prev, next) => prev + next)
      if (sum === 0 || delivered === 0) {
        return 0
      }
      return sum / delivered * 100
    },
    getTotalTableForEngagementPercent (metric) {
      var sum = 0
      var delivered = 0
      this.results.mailingsMetaData.forEach((mailing, mailingIndex) => {
        sum += this.engagementMetricsData.datasets.find(x => x.label === `${mailing.name} - ${metric}`).data.reduce((prev, next) => prev + next)
        delivered += this.results.mailingData.engagementSummary[mailingIndex].deliveredMessages
      })
      if (sum === 0 || delivered === 0) {
        return 0
      }
      return sum / delivered * 100
    },
    getNumberOfSendsPresented () {
      let total = this.results.mailingsMetaData.length
      return total
    },
    getEngagementHeaderColspan () {
      let total = this.results.mailingsMetaData.length > 1 ? 1 : 0
      return total + this.results.mailingsMetaData.length
    },
    getReadTimeHeaderColspan () {
      let total = this.results.mailingsMetaData.length
      return total
    },
    getTotalRowForEngagement (labelIndex, metric) {
      let total = 0
      this.results.mailingsMetaData.forEach((mailing, mailingIndex) => {
        total += this.engagementMetricsData.datasets.find(x => x.label === `${mailing.name} - ${metric}`).data[labelIndex]
      })
      return total
    },
    getTotalTableForEngagement (metric) {
      let total = 0
      this.results.mailingsMetaData.forEach((mailing, mailingIndex) => {
        total += this.engagementMetricsData.datasets.find(x => x.label === `${mailing.name} - ${metric}`).data.reduce((prev, next) => prev + next)
      })
      return total
    },
    async downloadEntireReport () {
      const exporter = new TableCSVExporter(this.tableElementsList, [document.getElementById('headerTable1'), document.getElementById('headerTable2')])
      var csvOutput = exporter.convertToCSV()

      let colCount = exporter.findLongestRowLength()
      csvOutput += this.$_downloadFileMixin_getChartReportAsText(this.results.readingEnvironmentsChart, '%', colCount)
      csvOutput += `${','.repeat(colCount - 1)}\n`
      csvOutput += this.$_downloadFileMixin_getChartReportAsText(this.results.emailClientDeviceSummary, '%', colCount)
      csvOutput += `${','.repeat(colCount - 1)}\n`
      csvOutput += this.$_downloadFileMixin_getChartReportAsText(this.results.viewingEngagementTotalSummary, '%', colCount)
      if (this.showAgeData) {
        csvOutput += `${','.repeat(colCount - 1)}\n`
        csvOutput += this.$_downloadFileMixin_getChartReportAsText(this.results.ageEngagementChart, '%', colCount, true)
      }

      const csvBlob = new Blob([csvOutput], { type: 'application/text' })
      const blobUrl = URL.createObjectURL(csvBlob)
      const anchorElement = document.createElement('a')

      anchorElement.href = blobUrl
      anchorElement.download = `${this.results.campaignMetaData.campaignNowwOrderNumber}-${this.results.campaignMetaData.campaignName.replace(/ /g, '')}-CR.csv`
      anchorElement.click()

      setTimeout(() => {
        URL.revokeObjectURL(blobUrl)
      }, 500)
    },
    // async downloadComprehensiveReportReceived () {
    //   var _this = this
    //   this.$store.commit('setLoadingMessage', 'Downloading Comprehensive Report')
    //   await azBlobService
    //     .downloadComprehensiveReportReceived(this.mailingItemId)
    //     .then(function (resp) {
    //       if (resp.errorResponse) {
    //         _this.$store.commit('setGlobalSnackbar', {
    //           message: resp.errorResponse,
    //           color: 'error'
    //         })
    //       } else {
    //         _this.$_downloadFileMixin_downloadResponse(resp)
    //       }
    //     })
    // },
    // async downloadComprehensiveReportOpens () {
    //   var _this = this
    //   this.$store.commit('setLoadingMessage', 'Downloading Comprehensive Report')
    //   await azBlobService
    //     .downloadComprehensiveReportOpens(this.mailingItemId)
    //     .then(function (resp) {
    //       if (resp.errorResponse) {
    //         _this.$store.commit('setGlobalSnackbar', {
    //           message: resp.errorResponse,
    //           color: 'error'
    //         })
    //       } else {
    //         _this.$_downloadFileMixin_downloadResponse(resp)
    //       }
    //     })
    // },
    // async downloadComprehensiveReportClicks () {
    //   var _this = this
    //   this.$store.commit('setLoadingMessage', 'Downloading Comprehensive Report')
    //   await azBlobService
    //     .downloadComprehensiveReportClicks(this.mailingItemId)
    //     .then(function (resp) {
    //       if (resp.errorResponse) {
    //         _this.$store.commit('setGlobalSnackbar', {
    //           message: resp.errorResponse,
    //           color: 'error'
    //         })
    //       } else {
    //         _this.$_downloadFileMixin_downloadResponse(resp)
    //       }
    //     })
    // },
    // async downloadComprehensiveReportOptOuts () {
    //   var _this = this
    //   this.$store.commit('setLoadingMessage', 'Downloading Comprehensive Report')
    //   await azBlobService
    //     .downloadComprehensiveReportOptOuts(this.mailingItemId)
    //     .then(function (resp) {
    //       if (resp.errorResponse) {
    //         _this.$store.commit('setGlobalSnackbar', {
    //           message: resp.errorResponse,
    //           color: 'error'
    //         })
    //       } else {
    //         _this.$_downloadFileMixin_downloadResponse(resp)
    //       }
    //     })
    // },
    // async downloadComprehensiveReportEnhanced () {
    //   var _this = this
    //   this.$store.commit('setLoadingMessage', 'Downloading Comprehensive Report')
    //   await azBlobService
    //     .downloadComprehensiveReportEnhanced(this.mailingItemId)
    //     .then(function (resp) {
    //       if (resp.errorResponse) {
    //         _this.$store.commit('setGlobalSnackbar', {
    //           message: resp.errorResponse,
    //           color: 'error'
    //         })
    //       } else {
    //         _this.$_downloadFileMixin_downloadResponse(resp)
    //       }
    //     })
    // },
    // async downloadLeadScoresReport () {
    //   var _this = this
    //   this.$store.commit('setLoadingMessage', 'Downloading Lead Scores Report')
    //   await azBlobService
    //     .downloadLeadScoresReport(this.mailingItemId)
    //     .then(function (resp) {
    //       if (resp.errorResponse) {
    //         _this.$store.commit('setGlobalSnackbar', {
    //           message: resp.errorResponse,
    //           color: 'error'
    //         })
    //       } else {
    //         _this.$_downloadFileMixin_downloadResponse(resp)
    //       }
    //     })
    // },
    generatePdfReport () {
      this.$refs.html2Pdf.generatePdf()

      // window.html2canvas = html2canvas
      // var doc = new jsPDF('p', 'pt', 'a4')
      // let _this = this
      // doc.html(document.querySelector('#html2Pdf'), {
      //   callback: function (pdf) {
      //     pdf.save(`${_this.results.campaignMetaData.campaignNowwOrderNumber}_${_this.results.campaignMetaData.campaignName}_CampaignReport.pdf`)
      //   }
      // })

      // const doc = new jsPDF({
      //   orientation: 'p',
      //   unit: 'px',
      //   format: 'a4',
      //   hotfixes: ['px_scaling']
      // })
      // let _this = this
      // console.log(this.$refs.html2Pdf2)
      // html2canvas(this.$refs.html2Pdf2, {
      //   width: doc.internal.pageSize.getWidth(),
      //   height: doc.internal.pageSize.getHeight()
      // }).then((canvas) => {
      //   const img = canvas.toDataURL('image/png')
      //   doc.addImage(img, 'PNG', 140, 10, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight())
      //   doc.save(`${_this.results.campaignMetaData.campaignNowwOrderNumber}_${_this.results.campaignMetaData.campaignName}_CampaignReport.pdf`)
      //   _this.afterPdfDownload()
      // })
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text('Generated by NOWW Platform - MMS, Inc. Confidential.  https://www.mmslists.com', (pdf.internal.pageSize.getWidth() * 0.26), (pdf.internal.pageSize.getHeight() - 0.2))
        }
      }).save()
      this.$_windowMixin_removeContentClass('pdf-letter-width')
      this.pdfMenu = false
    },
    beforePdfDownload () {
      this.$_windowMixin_addContentClass('pdf-letter-width')
    },
    afterPdfDownload () {
      this.$_windowMixin_removeContentClass('pdf-letter-width')
      this.pdfMenu = false
    }
  }
}
</script>

<style lang="scss">
.campaign-report *, .campaign-report .text-h4, .campaign-report .text-h5, .campaign-report .text-subtitle-1, .campaign-report .text-subtitle-2 {
  font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
  vertical-align: top;
}

.pdf-content {
  .pdf-header {
    display: flex;
    padding: 10px;
    padding-bottom: 5px;

    .text-h4 {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      font-size: 1.52rem;
      font-weight: bold;
      color: #6d2c8a;
    }

    .text-h5 {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      font-size: 1.0625rem;
      color: #6d2c8a;
    }
  }

  .summ-table {
    font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
  }

  .dist-eng-summ {
    font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
    text-align: center;

    .dist-eng-summ-title {
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: normal;
      font-weight: 700;
    }

    .dist-eng-summ-green {
      color: #4CAF50;
      font-weight: 700 !important;
    }

    .dist-eng-summ-gray {
      color: gray;
      font-weight: 700 !important;
    }
  }

  .dist-eng-charts {
    margin-left: 40px
  }

  .eng-summ-table {
    font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;

    .eng-summ-table-th {
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 700;
      letter-spacing: 0.0071428571em;
    }

    .eng-summ-table-row-top-border {
      border-top: 2px solid rgba(0, 0, 0, 0.36);
      text-align: right;
      font-weight: 700;
    }

    .eng-summ-table-head-total {
      border-top: 2px solid rgba(0, 0, 0, 0.36);
      border-right: 2px solid rgba(0, 0, 0, 0.12);
      text-align: right;
      font-weight: 700;
    }

    .eng-summ-table-bottom-total {
      border-right: 2px solid rgba(0, 0, 0, 0.12);
      text-align: right;
      font-weight: 700;
    }

    .eng-summ-table-td-total {
      text-align: right;
      font-weight: 700;
    }
  }

  .reading-eng {
    font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
    text-align: center;

    .read-eng-table {
      border-bottom: none
    }

    .reading-eng-desc {
      font-size: 1.3rem;
      line-height: 2rem;
      letter-spacing: normal;
      font-weight: 400;
      border-bottom: none;
      border:none
    }

    .reading-eng-summ {
      font-size: 1.80rem;
      font-weight: 400;
      line-height: 2.5rem;
      letter-spacing: 0.0073529412em;
    }

    .reading-eng-chart {
      margin-left: 25px;
    }
  }

  .reading-eng-th-text {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 700;
      letter-spacing: 0.0071428571em;
    }

  .reading-eng-trtitle-text {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      text-align: left;
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 700;
      letter-spacing: 0.0071428571em;
    }

  .reading-eng-td-row {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      text-align: right;
      font-weight: 400;
    }

  .reading-eng-td-total {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      text-align: right;
      font-weight: 700;
    }

  .title-bar {
    line-height: 2rem;
    letter-spacing: normal !important;
    background-color: #9e9e9e !important;
    border-color: #9e9e9e !important;

    .title-bar-text {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      font-size: 1.20rem;
      font-weight: bold;
      color: #FFFFFF !important
    }
  }

  .delv-met {
    font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;

    .delv-met-th-text {
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 700;
      letter-spacing: 0.0071428571em;
    }

    .delv-met-th-total {
      font-size: 0.875rem;
      text-align: right;
      font-weight: 700;
    }
  }
}

.campaign-report .table-border-top {
  border-top: 2px solid rgba(3, 2, 2, 0.12);
}
.campaign-report .table-border-top-bold {
  border-top: 2px solid rgba(0, 0, 0, 0.36);
}
.campaign-report .table-border-right {
  border-right: 2px solid rgba(0, 0, 0, 0.12);
}
.campaign-report .table-border-right-bold {
  border-right: 2px solid rgba(0, 0, 0, 0.36);
}
.campaign-report .table-border-bottom {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}
.campaign-report .table-border-bottom-bold {
  border-bottom: 2px solid rgba(0, 0, 0, 0.36) !important;
}
.campaign-report .table-border-left {
  border-left: 2px solid rgba(0, 0, 0, 0.12);
}
.campaign-report .table-border-left-bold {
  border-left: 2px solid rgba(0, 0, 0, 0.36);
}
.campaign-report td, .campaign-report th {
  padding: 0 4px !important
}
.campaign-report .winningCriteriaHeader {
  background-color: #A5D6A7;
}
.campaign-report .no-borders td {
  border-bottom: none !important;
}
.campaign-report .no-hover tbody {
     tr:hover {
        background-color: transparent !important;
     }
  }
</style>

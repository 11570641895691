var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "10" },
          attrs: {
            absolute: "",
            temporary: "",
            width: "512",
            height:
              _vm.$_windowMixin_contentHeight +
              _vm.$_windowMixin_getFooterHeight()
          },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _vm.loadingSent
            ? _c("v-skeleton-loader", { attrs: { type: "list-item@20" } })
            : _c(
                "v-list",
                { attrs: { nav: "", dense: "" } },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: {
                        value: _vm.campaignId,
                        "active-class": "deep-purple--text text--accent-4"
                      }
                    },
                    [
                      _vm._l(_vm.sentCampaigns, function(campaignItem, i) {
                        return [
                          i > 0 &&
                          !_vm.sentCampaigns[
                            i - 1
                          ].maxMailingScheduledDateLocal.isSame(
                            campaignItem.maxMailingScheduledDateLocal,
                            "date"
                          )
                            ? _c("v-divider", {
                                key: campaignItem.campaignId + "-D"
                              })
                            : _vm._e(),
                          i === 0 ||
                          !_vm.sentCampaigns[
                            i - 1
                          ].maxMailingScheduledDateLocal.isSame(
                            campaignItem.maxMailingScheduledDateLocal,
                            "date"
                          )
                            ? _c(
                                "v-subheader",
                                {
                                  key: campaignItem.campaignId + "-T",
                                  staticClass: "text-h5 font-weight-bold"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("displayDateFormat3")(
                                          campaignItem.maxMailingScheduledDateLocal
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item",
                            {
                              key: campaignItem.campaignId,
                              attrs: { value: campaignItem.campaignId }
                            },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        name: "CampaignReport",
                                        params: {
                                          campaignId: campaignItem.campaignId
                                        }
                                      })
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                color:
                                                  campaignItem.campaignStatus ===
                                                  3
                                                    ? "green lighten-2"
                                                    : "accent"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "displayTimeFormat3"
                                                    )(
                                                      campaignItem.maxMailingScheduledDateLocal
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "align-self-center",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                campaignItem.campaignName
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
        ],
        1
      ),
      _c("CampaignHeader", {
        ref: "campaignHeader",
        attrs: { disabled: "", "campaign-id": _vm.campaignId }
      }),
      _vm.reportLoading
        ? _c("v-skeleton-loader", { attrs: { type: "table" } })
        : _vm.results
        ? _c(
            "v-card",
            { ref: "html2Pdf2", staticClass: "campaign-report" },
            [
              _c(
                "v-card-text",
                { staticClass: "black--text font-weight-normal" },
                [
                  _c(
                    "vue-html2pdf",
                    {
                      ref: "html2Pdf",
                      attrs: {
                        id: "html2Pdf",
                        "show-layout": false,
                        "float-layout": false,
                        "enable-download": false,
                        "preview-modal": false,
                        filename:
                          _vm.results.campaignMetaData.campaignNowwOrderNumber +
                          "_" +
                          _vm.results.campaignMetaData.campaignName +
                          "_CampaignReport",
                        "pdf-quality": 2,
                        "manual-pagination": true,
                        margin: [20, 20],
                        "pdf-format": "letter",
                        "pdf-orientation": "landscape",
                        "pdf-content-width": "100%"
                      },
                      on: {
                        beforeDownload: function($event) {
                          return _vm.beforeDownload($event)
                        }
                      }
                    },
                    [
                      _c(
                        "section",
                        {
                          staticClass: "pdf-content",
                          attrs: { slot: "pdf-content" },
                          slot: "pdf-content"
                        },
                        [
                          _c(
                            "section",
                            { staticClass: "pdf-header" },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pb-4",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-h4 font-weight-bold"
                                        },
                                        [_vm._v("Med-E-Mail Analytics")]
                                      ),
                                      _c("br"),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-h5 font-weight-light"
                                        },
                                        [_vm._v("Campaign Report Summary")]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var onTooltip = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: !_vm.pdfMenu,
                                                            expression:
                                                              "!pdfMenu"
                                                          }
                                                        ],
                                                        staticClass: "ml-4",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.drawer = !_vm.drawer
                                                          }
                                                        }
                                                      },
                                                      onTooltip
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "tertiary"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        mdi-menu\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("View Other Sent Campaigns")
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            right: "",
                                            "x-offset": "",
                                            origin: "right",
                                            "close-on-click": false,
                                            transition: "slide-x-transition"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var onMenu = ref.on
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var onTooltip =
                                                                ref.on
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value: !_vm.pdfMenu,
                                                                          expression:
                                                                            "!pdfMenu"
                                                                        }
                                                                      ],
                                                                      attrs: {
                                                                        icon: ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.beforePdfDownload()
                                                                        }
                                                                      }
                                                                    },
                                                                    Object.assign(
                                                                      {},
                                                                      onMenu,
                                                                      onTooltip
                                                                    )
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "success"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            mdi-file-pdf-box\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Download Campaign Report PDF"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.pdfMenu,
                                            callback: function($$v) {
                                              _vm.pdfMenu = $$v
                                            },
                                            expression: "pdfMenu"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-text",
                                                { staticClass: "pa-5" },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "Formatting PDF for download..."
                                                    )
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "font-italic"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        If the report looks too small, resize your browser window!\n                      "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "tertiary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.afterPdfDownload()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        Cancel\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "success"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.generatePdfReport()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        Download\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var onTooltip = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: !_vm.pdfMenu,
                                                            expression:
                                                              "!pdfMenu"
                                                          }
                                                        ],
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.downloadEntireReport()
                                                          }
                                                        }
                                                      },
                                                      onTooltip
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "green lighten-3"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        mdi-file-download\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Download Campaign Report CSV"
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm.showAgeToggle
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var onTooltip = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value: !_vm.pdfMenu,
                                                                  expression:
                                                                    "!pdfMenu"
                                                                }
                                                              ],
                                                              attrs: {
                                                                icon: "",
                                                                color: "white"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.toggleAgeData = !_vm.toggleAgeData
                                                                }
                                                              }
                                                            },
                                                            onTooltip
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color: _vm.toggleAgeData
                                                                    ? "info"
                                                                    : "tertiary"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      _vm.toggleAgeData
                                                                        ? "mdi-toggle-switch"
                                                                        : "mdi-toggle-switch-off"
                                                                    ) +
                                                                    "\n                      "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3947975700
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Toggle Age Data " +
                                                    _vm._s(
                                                      _vm.toggleAgeData
                                                        ? "off"
                                                        : "on"
                                                    )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          height: "73",
                                          contain: "",
                                          src: require("@/assets/mms-signature.jpg")
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "divider1 mb-4" }),
                          _c(
                            "section",
                            { staticClass: "summ-table" },
                            [
                              _c(
                                "v-card",
                                { staticClass: "pa-2 ma-2" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "4" } },
                                        [
                                          _c("v-simple-table", {
                                            attrs: {
                                              id: "headerTable1",
                                              "v-if": false,
                                              dense: ""
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function() {
                                                  return [
                                                    _c("tbody", [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              width: "175"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            Client Name:\n                          "
                                                            )
                                                          ]
                                                        ),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.results
                                                                .customerName
                                                            )
                                                          )
                                                        ])
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              width: "175"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            Campaign Name:\n                          "
                                                            )
                                                          ]
                                                        ),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.results
                                                                .campaignName
                                                            )
                                                          )
                                                        ])
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              width: "175"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            NOWW Job Number:\n                          "
                                                            )
                                                          ]
                                                        ),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.results
                                                                .campaignMetaData
                                                                .campaignNowwOrderNumber
                                                            )
                                                          )
                                                        ])
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              width: "175"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            Lists:\n                          "
                                                            )
                                                          ]
                                                        ),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.results.lists.join(
                                                                ", "
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ])
                                                    ])
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _c("v-divider", {
                                        staticClass: "mx-2 d-none d-sm-flex",
                                        attrs: { vertical: "" }
                                      }),
                                      _vm.pdfMenu
                                        ? _c(
                                            "v-row",
                                            {
                                              staticStyle: {
                                                "margin-top": "20px"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "10"
                                                  }
                                                },
                                                [
                                                  _c("v-simple-table", {
                                                    attrs: {
                                                      id: "headerTable2",
                                                      "v-if": false,
                                                      dense: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "tbody",
                                                                _vm._l(
                                                                  _vm.results
                                                                    .mailingsMetaData,
                                                                  function(
                                                                    mailing
                                                                  ) {
                                                                    return _c(
                                                                      "tr",
                                                                      {
                                                                        key:
                                                                          mailing.id
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left",
                                                                            attrs: {
                                                                              width:
                                                                                "125"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  mailing.name
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left",
                                                                            attrs: {
                                                                              width:
                                                                                "100"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              Resend Type:\n                            "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                mailing.sequence >
                                                                                  1
                                                                                  ? _vm.$_resendType_display(
                                                                                      mailing.resendType
                                                                                    )
                                                                                  : "N/A"
                                                                              )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left",
                                                                            attrs: {
                                                                              width:
                                                                                "125"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  mailing.winningDistribution >
                                                                                    0
                                                                                    ? "Split "
                                                                                    : ""
                                                                                ) +
                                                                                "Send Schedule:\n                            "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "displayDateTimeFormat"
                                                                                )(
                                                                                  _vm.$_global_parseAsUtc(
                                                                                    mailing.scheduleDate
                                                                                  )
                                                                                )
                                                                              ) +
                                                                                " - " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "displayDateTimeFormat"
                                                                                  )(
                                                                                    _vm.$_global_parseAsUtc(
                                                                                      mailing.scheduleDateEnd
                                                                                    )
                                                                                  )
                                                                                )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        mailing.winningDistribution >
                                                                        0
                                                                          ? _c(
                                                                              "td",
                                                                              {
                                                                                staticClass:
                                                                                  "text-left",
                                                                                attrs: {
                                                                                  width:
                                                                                    "175"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              Winning Send Schedule:\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        mailing.winningDistribution >
                                                                        0
                                                                          ? _c(
                                                                              "td",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm._f(
                                                                                        "displayDateTimeFormat"
                                                                                      )(
                                                                                        _vm.$_global_parseAsUtc(
                                                                                          mailing.winningScheduleDate
                                                                                        )
                                                                                      )
                                                                                    ) +
                                                                                    " - " +
                                                                                    _vm._s(
                                                                                      _vm._f(
                                                                                        "displayDateTimeFormat"
                                                                                      )(
                                                                                        _vm.$_global_parseAsUtc(
                                                                                          mailing.winningScheduleDateEnd
                                                                                        )
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1577166070
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "7" } },
                                            [
                                              _c("v-simple-table", {
                                                attrs: {
                                                  id: "headerTable2",
                                                  "v-if": false,
                                                  dense: ""
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "tbody",
                                                          _vm._l(
                                                            _vm.results
                                                              .mailingsMetaData,
                                                            function(mailing) {
                                                              return _c(
                                                                "tr",
                                                                {
                                                                  key:
                                                                    mailing.id
                                                                },
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-left",
                                                                      attrs: {
                                                                        width:
                                                                          "125"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            mailing.name
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-left",
                                                                      attrs: {
                                                                        width:
                                                                          "100"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            Resend Type:\n                          "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        mailing.sequence >
                                                                          1
                                                                          ? _vm.$_resendType_display(
                                                                              mailing.resendType
                                                                            )
                                                                          : "N/A"
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-left",
                                                                      attrs: {
                                                                        width:
                                                                          "125"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            mailing.winningDistribution >
                                                                              0
                                                                              ? "Split "
                                                                              : ""
                                                                          ) +
                                                                          "Send Schedule:\n                          "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "displayDateTimeFormat"
                                                                        )(
                                                                          _vm.$_global_parseAsUtc(
                                                                            mailing.scheduleDate
                                                                          )
                                                                        )
                                                                      ) +
                                                                        " - " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "displayDateTimeFormat"
                                                                          )(
                                                                            _vm
                                                                              .moment(
                                                                                _vm.$_global_parseAsUtc(
                                                                                  mailing.scheduleDate
                                                                                )
                                                                              )
                                                                              .add(
                                                                                1,
                                                                                "d"
                                                                              )
                                                                          )
                                                                        )
                                                                    )
                                                                  ]),
                                                                  mailing.winningDistribution >
                                                                  0
                                                                    ? _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left",
                                                                          attrs: {
                                                                            width:
                                                                              "175"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            Winning Send Schedule:\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  mailing.winningDistribution >
                                                                  0
                                                                    ? _c("td", [
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "displayDateTimeFormat"
                                                                              )(
                                                                                _vm.$_global_parseAsUtc(
                                                                                  mailing.winningScheduleDate
                                                                                )
                                                                              )
                                                                            ) +
                                                                            " - " +
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "displayDateTimeFormat"
                                                                              )(
                                                                                _vm
                                                                                  .moment(
                                                                                    _vm.$_global_parseAsUtc(
                                                                                      mailing.winningScheduleDate
                                                                                    )
                                                                                  )
                                                                                  .add(
                                                                                    1,
                                                                                    "d"
                                                                                  )
                                                                              )
                                                                            ) +
                                                                            "\n                          "
                                                                        )
                                                                      ])
                                                                    : _vm._e()
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "my-4" }),
                          (_vm.results.mailingsMetaData[
                            _vm.results.mailingsMetaData.length - 1
                          ].winningDistribution > 0 &&
                            _vm.results.mailingsMetaData[
                              _vm.results.mailingsMetaData.length - 1
                            ].winningScheduleDateEnd !== null &&
                            _vm
                              .moment(
                                _vm.results.mailingsMetaData[
                                  _vm.results.mailingsMetaData.length - 1
                                ].winningScheduleDateEnd
                              )
                              .isSameOrAfter(
                                _vm.moment(new Date().getTime()),
                                "day"
                              )) ||
                          (_vm.results.mailingsMetaData[
                            _vm.results.mailingsMetaData.length - 1
                          ].winningDistribution <= 0 &&
                            _vm.results.mailingsMetaData[
                              _vm.results.mailingsMetaData.length - 1
                            ].scheduleDateEnd !== null &&
                            _vm
                              .moment(
                                _vm.results.mailingsMetaData[
                                  _vm.results.mailingsMetaData.length - 1
                                ].scheduleDateEnd
                              )
                              .isSameOrAfter(
                                _vm.moment(new Date().getTime()),
                                "day"
                              ))
                            ? _c(
                                "v-card-subtitle",
                                {
                                  staticClass:
                                    "red--text font-weight-bold text-h5 pa-2 mb-4"
                                },
                                [
                                  _vm._v(
                                    "\n            The email is still sending. Please check back later for an updated Campaign Report which will be completed on (" +
                                      _vm._s(
                                        _vm._f("displayDateFormat")(
                                          _vm.results.mailingsMetaData[
                                            _vm.results.mailingsMetaData
                                              .length - 1
                                          ].winningDistribution > 0
                                            ? _vm
                                                .moment(
                                                  _vm.$_global_parseAsUtc(
                                                    _vm.results
                                                      .mailingsMetaData[
                                                      _vm.results
                                                        .mailingsMetaData
                                                        .length - 1
                                                    ].winningScheduleDateEnd
                                                  )
                                                )
                                                .add(1, "d")
                                            : _vm
                                                .moment(
                                                  _vm.$_global_parseAsUtc(
                                                    _vm.results
                                                      .mailingsMetaData[
                                                      _vm.results
                                                        .mailingsMetaData
                                                        .length - 1
                                                    ].scheduleDateEnd
                                                  )
                                                )
                                                .add(1, "d")
                                        )
                                      ) +
                                      ").\n            "
                                  ),
                                  _c("br"),
                                  _vm.results.mailingsMetaData.length > 1
                                    ? _c(
                                        "span",
                                        [
                                          _vm._v(
                                            "\n              If you want to see metrics for individual mailings on this campaign, check out the Single Mailing Report(s):\n              "
                                          ),
                                          _c("br"),
                                          _vm._l(
                                            _vm.results.mailingsMetaData,
                                            function(mailing, index) {
                                              return _c(
                                                "div",
                                                { key: mailing.id },
                                                [
                                                  index !==
                                                  _vm.results.mailingsMetaData
                                                    .length -
                                                    1
                                                    ? _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name:
                                                                "SingleMailingReport",
                                                              params: {
                                                                campaignId:
                                                                  mailing.campaignId,
                                                                mailingItemId:
                                                                  mailing.id
                                                              }
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                  " +
                                                              _vm._s(
                                                                mailing.name
                                                              ) +
                                                              "\n                "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          (_vm.results.mailingsMetaData[
                            _vm.results.mailingsMetaData.length - 1
                          ].winningDistribution > 0 &&
                            _vm.results.mailingsMetaData[
                              _vm.results.mailingsMetaData.length - 1
                            ].winningScheduleDateEnd !== null &&
                            _vm
                              .moment(
                                _vm.results.mailingsMetaData[
                                  _vm.results.mailingsMetaData.length - 1
                                ].winningScheduleDateEnd
                              )
                              .isSameOrAfter(
                                _vm.moment(new Date().getTime()),
                                "day"
                              )) ||
                          (_vm.results.mailingsMetaData[
                            _vm.results.mailingsMetaData.length - 1
                          ].winningDistribution <= 0 &&
                            _vm.results.mailingsMetaData[
                              _vm.results.mailingsMetaData.length - 1
                            ].scheduleDateEnd !== null &&
                            _vm
                              .moment(
                                _vm.results.mailingsMetaData[
                                  _vm.results.mailingsMetaData.length - 1
                                ].scheduleDateEnd
                              )
                              .isSameOrAfter(
                                _vm.moment(new Date().getTime()),
                                "day"
                              ))
                            ? _c("v-divider", { staticClass: "my-4" })
                            : _vm._e(),
                          _c("div", { staticClass: "html2pdf__page-break" }),
                          _vm.results.deliverabilityMetricsTotals
                            .totalAudience > 0
                            ? [
                                _c(
                                  "section",
                                  { staticClass: "title-bar" },
                                  [
                                    _c(
                                      "v-card-subtitle",
                                      {
                                        staticClass:
                                          "grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text",
                                        staticStyle: { display: "flex" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Deliverability Metrics\n                "
                                        ),
                                        _c("v-spacer"),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !_vm.pdfMenu,
                                                                expression:
                                                                  "!pdfMenu"
                                                              }
                                                            ],
                                                            attrs: {
                                                              icon: "",
                                                              color: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.$_downloadFileMixin_downloadReportTable(
                                                                  "deliverabilityMetrics",
                                                                  _vm.results
                                                                    .campaignMetaData
                                                                    .campaignNowwOrderNumber +
                                                                    "-" +
                                                                    _vm.results.campaignMetaData.campaignName.replace(
                                                                      / /g,
                                                                      ""
                                                                    ) +
                                                                    "-DeliverabilityMetrics"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          onTooltip
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "green lighten-3"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        mdi-file-download\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2572071736
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Download Deliverability Metrics"
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg:
                                            _vm.results.mailingsMetaData
                                              .length > 1 && _vm.pdfMenu
                                              ? "12"
                                              : "6",
                                          sm: "6"
                                        }
                                      },
                                      [
                                        _c(
                                          "section",
                                          { staticClass: "delv-met" },
                                          [
                                            _c(
                                              "v-card",
                                              { staticClass: "pa-2 ma-2" },
                                              [
                                                _c("v-simple-table", {
                                                  attrs: {
                                                    id: "deliverabilityMetrics",
                                                    dense: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function() {
                                                          return [
                                                            _c("thead", [
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c("th", {
                                                                    staticClass:
                                                                      "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                                    style: [
                                                                      {
                                                                        color:
                                                                          "#6d2c8a",
                                                                        "text-align":
                                                                          "right"
                                                                      }
                                                                    ]
                                                                  }),
                                                                  _vm.results
                                                                    .mailingData
                                                                    .deliverabilityMetrics
                                                                    .length > 1
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .mailingData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            mailing,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "th",
                                                                                {
                                                                                  key: i,
                                                                                  staticClass:
                                                                                    "text-right primary--text font-weight-bold text-subtitle-2 table-border-left table-border-bottom-bold delv-met-th-text",
                                                                                  style: [
                                                                                    {
                                                                                      color:
                                                                                        "#6d2c8a",
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .results
                                                                                          .mailingsMetaData[
                                                                                          i
                                                                                        ]
                                                                                          .name
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "th",
                                                                                {
                                                                                  key:
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                                                  style: [
                                                                                    {
                                                                                      color:
                                                                                        "#6d2c8a",
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .results
                                                                                          .mailingsMetaData[
                                                                                          i
                                                                                        ]
                                                                                          .name
                                                                                      ) +
                                                                                      " Rate\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  !_vm.pdfMenu ||
                                                                  _vm.results
                                                                    .mailingData
                                                                    .deliverabilityMetrics
                                                                    .length < 4
                                                                    ? [
                                                                        _c(
                                                                          "th",
                                                                          {
                                                                            staticClass:
                                                                              "text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold delv-met-th-total",
                                                                            style: {
                                                                              color:
                                                                                "#6d2c8a"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                Total\n                              "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "th",
                                                                          {
                                                                            staticClass:
                                                                              "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-total",
                                                                            style: {
                                                                              color:
                                                                                "#6d2c8a"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                Rate\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              )
                                                            ]),
                                                            _c("tbody", [
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                                      style: {
                                                                        color:
                                                                          "#6d2c8a"
                                                                      },
                                                                      attrs: {
                                                                        width:
                                                                          "200"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              Sent\n                            "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm.results
                                                                    .mailingData
                                                                    .deliverabilityMetrics
                                                                    .length > 1
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .mailingData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            deliverabilityMetrics,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key: i,
                                                                                  staticClass:
                                                                                    "text-right table-border-left table-border-bottom-bold",
                                                                                  style: [
                                                                                    {
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          deliverabilityMetrics.sentMessages
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right table-border-bottom-bold",
                                                                                  style: [
                                                                                    {
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "percent"
                                                                                        )(
                                                                                          deliverabilityMetrics.sentRate,
                                                                                          2
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  !_vm.pdfMenu ||
                                                                  _vm.results
                                                                    .mailingData
                                                                    .deliverabilityMetrics
                                                                    .length < 4
                                                                    ? [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right table-border-left-bold font-weight-bold table-border-bottom-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "commaNumber"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .sentMessages
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right font-weight-bold table-border-bottom-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "percent"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .sentRate,
                                                                                    2
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              ),
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-left primary--text font-weight-bold text-subtitle-2 delv-met-th-text",
                                                                      style: {
                                                                        color:
                                                                          "#6d2c8a"
                                                                      },
                                                                      attrs: {
                                                                        width:
                                                                          "200"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              Hard Bounce\n                            "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm.results
                                                                    .mailingData
                                                                    .deliverabilityMetrics
                                                                    .length > 1
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .mailingData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            deliverabilityMetrics,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key: i,
                                                                                  staticClass:
                                                                                    "text-right table-border-left",
                                                                                  style: [
                                                                                    {
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          deliverabilityMetrics.hardBounces
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right",
                                                                                  style: [
                                                                                    {
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "percent"
                                                                                        )(
                                                                                          deliverabilityMetrics.hardBounceRate,
                                                                                          2
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  !_vm.pdfMenu ||
                                                                  _vm.results
                                                                    .mailingData
                                                                    .deliverabilityMetrics
                                                                    .length < 4
                                                                    ? [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right table-border-left-bold font-weight-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "commaNumber"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .hardBounces
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right font-weight-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "percent"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .hardBounceRate,
                                                                                    2
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              ),
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-left primary--text font-weight-bold text-subtitle-2 delv-met-th-text",
                                                                      style: {
                                                                        color:
                                                                          "#6d2c8a"
                                                                      },
                                                                      attrs: {
                                                                        width:
                                                                          "200"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              Soft Bounce\n                            "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm.results
                                                                    .mailingData
                                                                    .deliverabilityMetrics
                                                                    .length > 1
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .mailingData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            deliverabilityMetrics,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key: i,
                                                                                  staticClass:
                                                                                    "text-right table-border-left",
                                                                                  style: [
                                                                                    {
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          deliverabilityMetrics.softBounces
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right",
                                                                                  style: [
                                                                                    {
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "percent"
                                                                                        )(
                                                                                          deliverabilityMetrics.softBounceRate,
                                                                                          2
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  !_vm.pdfMenu ||
                                                                  _vm.results
                                                                    .mailingData
                                                                    .deliverabilityMetrics
                                                                    .length < 4
                                                                    ? [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right table-border-left-bold font-weight-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "commaNumber"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .softBounces
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right font-weight-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "percent"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .softBounceRate,
                                                                                    2
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              ),
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-left primary--text font-weight-bold text-subtitle-2 delv-met-th-text",
                                                                      style: {
                                                                        color:
                                                                          "#6d2c8a"
                                                                      },
                                                                      attrs: {
                                                                        width:
                                                                          "200"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              Delivered Messages\n                            "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm.results
                                                                    .mailingData
                                                                    .deliverabilityMetrics
                                                                    .length > 1
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .mailingData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            deliverabilityMetrics,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key: i,
                                                                                  staticClass:
                                                                                    "text-right table-border-left",
                                                                                  style: [
                                                                                    {
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          deliverabilityMetrics.deliveredMessages
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right",
                                                                                  style: [
                                                                                    {
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "percent"
                                                                                        )(
                                                                                          deliverabilityMetrics.deliveredRate,
                                                                                          2
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  !_vm.pdfMenu ||
                                                                  _vm.results
                                                                    .mailingData
                                                                    .deliverabilityMetrics
                                                                    .length < 4
                                                                    ? [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right table-border-left-bold font-weight-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "commaNumber"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .deliveredMessages
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right font-weight-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "percent"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .deliveredRate,
                                                                                    2
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              )
                                                            ])
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    2611314820
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm.pdfMenu &&
                                        _vm.results.mailingData
                                          .deliverabilityMetrics.length >= 4
                                          ? [
                                              _c(
                                                "section",
                                                {
                                                  staticClass: "delv-met",
                                                  style: [
                                                    {
                                                      "margin-top": "20px",
                                                      "margin-bottom": "20px"
                                                    }
                                                  ]
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticStyle: {
                                                        display: "inline-table"
                                                      },
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("tr", [
                                                        _c("th", {
                                                          staticClass:
                                                            "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                          style: [
                                                            {
                                                              "border-bottom":
                                                                "2px solid rgba(0, 0, 0, 0.12)"
                                                            }
                                                          ]
                                                        }),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#6d2c8a",
                                                                "text-align":
                                                                  "right",
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Total\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#6d2c8a",
                                                                "text-align":
                                                                  "right",
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Rate\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#6d2c8a",
                                                                "text-align":
                                                                  "left",
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ],
                                                            attrs: {
                                                              width: "200"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Sent\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right table-border-left-bold font-weight-bold table-border-bottom-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "commaNumber"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .sentMessages
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right font-weight-bold table-border-bottom-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "percent"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .sentRate,
                                                                    2
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#6d2c8a",
                                                                "text-align":
                                                                  "left",
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ],
                                                            attrs: {
                                                              width: "200"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Hard Bounce\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right table-border-left-bold font-weight-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "commaNumber"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .hardBounces
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right font-weight-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "percent"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .hardBounceRate,
                                                                    2
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#6d2c8a",
                                                                "text-align":
                                                                  "left",
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ],
                                                            attrs: {
                                                              width: "200"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Soft Bounce\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right table-border-left-bold font-weight-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "commaNumber"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .softBounces
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right font-weight-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "percent"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .softBounceRate,
                                                                    2
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#6d2c8a",
                                                                "text-align":
                                                                  "left",
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ],
                                                            attrs: {
                                                              width: "200"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Delivered Messages\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right table-border-left-bold font-weight-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "commaNumber"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .deliveredMessages
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right font-weight-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "percent"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .deliveredRate,
                                                                    2
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm:
                                            _vm.results.mailingsMetaData
                                              .length > 1 && !_vm.pdfMenu
                                              ? "3"
                                              : "6"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          { staticClass: "pa-2 ma-2" },
                                          [
                                            _c("pie-chart", {
                                              staticClass: "pb-2",
                                              attrs: {
                                                "chart-data":
                                                  _vm.results
                                                    .deliverabilityMetricsTotalsPieChart,
                                                options: _vm.getChartOptionsPie(
                                                  "Total Deliverability"
                                                ),
                                                height: 224
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.results.mailingsMetaData.length > 1
                                      ? _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: _vm.pdfMenu ? "6" : "3",
                                              sm: "3"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              { staticClass: "pa-2 ma-2" },
                                              [
                                                _c("pie-chart", {
                                                  staticClass: "pb-2",
                                                  attrs: {
                                                    "chart-data":
                                                      _vm.results
                                                        .sentCountPieChart,
                                                    options: _vm.getChartOptionsPie(
                                                      "Sent Count"
                                                    ),
                                                    height: 224
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                !_vm.pdfMenu
                                  ? _c("v-divider", { staticClass: "my-4" })
                                  : _vm._e(),
                                _c("div", {
                                  staticClass: "html2pdf__page-break"
                                }),
                                _c(
                                  "section",
                                  { staticClass: "title-bar" },
                                  [
                                    _c(
                                      "v-card-subtitle",
                                      {
                                        staticClass:
                                          "grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text",
                                        staticStyle: { display: "flex" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Engagement Summary\n                "
                                        ),
                                        _c("v-spacer"),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !_vm.pdfMenu,
                                                                expression:
                                                                  "!pdfMenu"
                                                              }
                                                            ],
                                                            attrs: {
                                                              icon: "",
                                                              color: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.$_downloadFileMixin_downloadReportTable(
                                                                  "engagementSummaryByDay",
                                                                  _vm.results
                                                                    .campaignMetaData
                                                                    .campaignNowwOrderNumber +
                                                                    "-" +
                                                                    _vm.results.campaignMetaData.campaignName.replace(
                                                                      / /g,
                                                                      ""
                                                                    ) +
                                                                    "-EngagementSummaryByDay"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          onTooltip
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "green lighten-3"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        mdi-file-download\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              79227832
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Download Engagement Summary by Day"
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !_vm.pdfMenu,
                                                                expression:
                                                                  "!pdfMenu"
                                                              }
                                                            ],
                                                            attrs: {
                                                              icon: "",
                                                              color: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.toggleGrossData = !_vm.toggleGrossData
                                                              }
                                                            }
                                                          },
                                                          onTooltip
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: _vm.toggleGrossData
                                                                  ? "light-blue lighten-4"
                                                                  : "white"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.toggleGrossData
                                                                      ? "mdi-toggle-switch"
                                                                      : "mdi-toggle-switch-off"
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3394166472
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Toggle Gross/Opt Out Data " +
                                                  _vm._s(
                                                    _vm.toggleGrossData
                                                      ? "off"
                                                      : "on"
                                                  )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "section",
                                        { staticClass: "dist-eng-summ" },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "pa-2 ma-2 text-center"
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  attrs: {
                                                    "no-gutters": "",
                                                    justify: "center"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-h5 font-weight-bold pa-2 dist-eng-summ-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Distinct Engagement Summary\n                        "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-h5 pb-2"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          You delivered messages to "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "green--text font-weight-bold dist-eng-summ-green"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "commaNumber"
                                                                  )(
                                                                    _vm.results
                                                                      .distinctMetrics
                                                                      .distinctReceived
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " individuals in your campaign.\n                        "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-h5 pb-2 text-center"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Of those delivered messages...\n                          "
                                                          ),
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n                            Your campaign engaged with "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "green--text font-weight-bold dist-eng-summ-green"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "commaNumber"
                                                                    )(
                                                                      _vm
                                                                        .results
                                                                        .distinctMetrics
                                                                        .distinctOpens
                                                                    )
                                                                  ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      _vm
                                                                        .results
                                                                        .distinctMetrics
                                                                        .distinctOpenRate
                                                                    ) +
                                                                    "%)"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " individuals!\n                          "
                                                            )
                                                          ]),
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n                            You got clicks from "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "green--text font-weight-bold dist-eng-summ-green"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "commaNumber"
                                                                    )(
                                                                      _vm
                                                                        .results
                                                                        .distinctMetrics
                                                                        .distinctClicks
                                                                    )
                                                                  ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      _vm
                                                                        .results
                                                                        .distinctMetrics
                                                                        .distinctClickRate
                                                                    ) +
                                                                    "%)"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " individuals!\n                          "
                                                            )
                                                          ]),
                                                          _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.toggleGrossData,
                                                                  expression:
                                                                    "toggleGrossData"
                                                                }
                                                              ]
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "grey--text font-weight-bold dist-eng-summ-gray"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "commaNumber"
                                                                      )(
                                                                        _vm
                                                                          .results
                                                                          .distinctMetrics
                                                                          .distinctOptOuts
                                                                      )
                                                                    ) +
                                                                      " (" +
                                                                      _vm._s(
                                                                        _vm
                                                                          .results
                                                                          .distinctMetrics
                                                                          .distinctOptOutRate
                                                                      ) +
                                                                      "%)"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " individuals opted out of your emails.\n                          "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "pa-2 ma-2 dist-eng-charts"
                                          },
                                          [
                                            _c("bar-chart", {
                                              attrs: {
                                                "chart-data":
                                                  _vm.results.clickToOpenChart,
                                                options: _vm.getChartOptionsBarPercent(
                                                  "Unique Click to Open %",
                                                  "",
                                                  ""
                                                ),
                                                height: 400
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "9" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "pa-2 ma-2 dist-eng-charts"
                                          },
                                          [
                                            _c("line-chart", {
                                              attrs: {
                                                "chart-data":
                                                  _vm.engagementMetricsData,
                                                options: _vm.getChartOptionsLine(
                                                  "Clicks/Opens"
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-divider", {
                                          staticClass: "my-4"
                                        }),
                                        _c(
                                          "section",
                                          { staticClass: "eng-summ-table" },
                                          [
                                            _c(
                                              "v-card",
                                              { staticClass: "pa-2 ma-2" },
                                              [
                                                _c("v-simple-table", {
                                                  attrs: {
                                                    id:
                                                      "engagementSummaryByDay",
                                                    dense: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function() {
                                                          return [
                                                            _c("thead", [
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c("th", {
                                                                    staticClass:
                                                                      "text-left primary--text font-weight-bold text-subtitle-2 eng-summ-table-th",
                                                                    class: {
                                                                      "table-border-bottom-bold":
                                                                        _vm
                                                                          .results
                                                                          .mailingsMetaData
                                                                          .length <=
                                                                        1
                                                                    },
                                                                    style: {
                                                                      color:
                                                                        "#6d2c8a"
                                                                    }
                                                                  }),
                                                                  _vm._l(
                                                                    _vm.engagementMetricsComputed,
                                                                    function(
                                                                      metric,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "th",
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold eng-summ-table-th",
                                                                          class: {
                                                                            "table-border-bottom-bold":
                                                                              _vm
                                                                                .results
                                                                                .mailingsMetaData
                                                                                .length <=
                                                                              1
                                                                          },
                                                                          style: {
                                                                            color:
                                                                              "#6d2c8a"
                                                                          },
                                                                          attrs: {
                                                                            colspan: _vm.getEngagementHeaderColspan()
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm.pdfMenu &&
                                                                          _vm
                                                                            .results
                                                                            .mailingsMetaData
                                                                            .length ===
                                                                            1
                                                                            ? _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    color:
                                                                                      _vm
                                                                                        .engagementMetricsData
                                                                                        .datasets[
                                                                                        index
                                                                                      ]
                                                                                        .backgroundColor
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                mdi-square-rounded\n                              "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                metric
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              ),
                                                              _vm.results
                                                                .mailingsMetaData
                                                                .length > 1
                                                                ? _c(
                                                                    "tr",
                                                                    [
                                                                      _c(
                                                                        "th",
                                                                        {
                                                                          staticClass:
                                                                            "text-left primary--text font-weight-bold text-subtitle-2 eng-summ-table-th",
                                                                          style: {
                                                                            color:
                                                                              "#6d2c8a"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              Date\n                            "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._l(
                                                                        _vm.engagementMetricsComputed,
                                                                        function(
                                                                          metrics,
                                                                          n
                                                                        ) {
                                                                          return [
                                                                            _vm._l(
                                                                              _vm
                                                                                .results
                                                                                .mailingData
                                                                                .engagementSummary,
                                                                              function(
                                                                                mailing,
                                                                                i
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      key:
                                                                                        n +
                                                                                        "-" +
                                                                                        i,
                                                                                      staticClass:
                                                                                        "text-right primary--text font-weight-bold text-subtitle-2 eng-summ-table-th",
                                                                                      class: {
                                                                                        "table-border-left-bold":
                                                                                          i ===
                                                                                          0
                                                                                      },
                                                                                      style: {
                                                                                        color:
                                                                                          "#6d2c8a"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      !_vm.pdfMenu
                                                                                        ? _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              attrs: {
                                                                                                color:
                                                                                                  _vm
                                                                                                    .engagementMetricsData
                                                                                                    .datasets[
                                                                                                    i *
                                                                                                      _vm
                                                                                                        .engagementMetricsComputed
                                                                                                        .length +
                                                                                                      n
                                                                                                  ]
                                                                                                    .backgroundColor
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                    mdi-square-rounded\n                                  "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _vm._v(
                                                                                        "\n                                  " +
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .results
                                                                                              .mailingsMetaData[
                                                                                              i
                                                                                            ]
                                                                                              .name
                                                                                          ) +
                                                                                          "\n                                "
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ]
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "th",
                                                                              {
                                                                                key:
                                                                                  "t-" +
                                                                                  n,
                                                                                staticClass:
                                                                                  "text-right primary--text font-weight-bold text-subtitle-2 table-border-left eng-summ-table-th",
                                                                                style: {
                                                                                  color:
                                                                                    "#6d2c8a"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                Total\n                              "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                : _vm._e()
                                                            ]),
                                                            _c(
                                                              "tbody",
                                                              [
                                                                _vm._l(
                                                                  _vm
                                                                    .engagementMetricsData
                                                                    .labels,
                                                                  function(
                                                                    label,
                                                                    i
                                                                  ) {
                                                                    return _c(
                                                                      "tr",
                                                                      {
                                                                        key:
                                                                          "" +
                                                                          label,
                                                                        staticStyle: {
                                                                          display:
                                                                            "none"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left primary--text font-weight-bold text-subtitle-2"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  label
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._l(
                                                                          _vm.engagementMetricsComputed,
                                                                          function(
                                                                            metric
                                                                          ) {
                                                                            return [
                                                                              _vm._l(
                                                                                _vm
                                                                                  .results
                                                                                  .mailingsMetaData,
                                                                                function(
                                                                                  mailing,
                                                                                  mailingIndex
                                                                                ) {
                                                                                  return _c(
                                                                                    "td",
                                                                                    {
                                                                                      key:
                                                                                        metric +
                                                                                        "-" +
                                                                                        mailing.name +
                                                                                        "-" +
                                                                                        i,
                                                                                      staticClass:
                                                                                        "text-right",
                                                                                      class: {
                                                                                        "table-border-left-bold":
                                                                                          mailingIndex ===
                                                                                          0
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                " +
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "commaNumber"
                                                                                            )(
                                                                                              _vm.engagementMetricsData.datasets.find(
                                                                                                function(
                                                                                                  x
                                                                                                ) {
                                                                                                  return (
                                                                                                    x.label ===
                                                                                                    mailing.name +
                                                                                                      " - " +
                                                                                                      metric
                                                                                                  )
                                                                                                }
                                                                                              )
                                                                                                .data[
                                                                                                i
                                                                                              ]
                                                                                            )
                                                                                          ) +
                                                                                          "\n                              "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              _vm
                                                                                .results
                                                                                .mailingsMetaData
                                                                                .length >
                                                                              1
                                                                                ? _c(
                                                                                    "td",
                                                                                    {
                                                                                      key:
                                                                                        "t-" +
                                                                                        metric,
                                                                                      staticClass:
                                                                                        "text-right font-weight-bold table-border-left"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                " +
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "commaNumber"
                                                                                            )(
                                                                                              _vm.getTotalRowForEngagement(
                                                                                                i,
                                                                                                metric
                                                                                              )
                                                                                            )
                                                                                          ) +
                                                                                          "\n                              "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          }
                                                                        )
                                                                      ],
                                                                      2
                                                                    )
                                                                  }
                                                                ),
                                                                _c(
                                                                  "tr",
                                                                  [
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-left primary--text font-weight-bold text-subtitle-2 table-border-top-bold eng-summ-table-row-top-border",
                                                                        style: {
                                                                          color:
                                                                            "#6d2c8a"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              Total\n                            "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._l(
                                                                      _vm.engagementMetricsComputed,
                                                                      function(
                                                                        metric
                                                                      ) {
                                                                        return [
                                                                          _vm._l(
                                                                            _vm
                                                                              .results
                                                                              .mailingsMetaData,
                                                                            function(
                                                                              mailing,
                                                                              mailingIndex
                                                                            ) {
                                                                              return _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    "t-" +
                                                                                    metric +
                                                                                    "-" +
                                                                                    mailing.name,
                                                                                  staticClass:
                                                                                    "text-right table-border-top-bold font-weight-bold eng-summ-table-row-top-border",
                                                                                  class: {
                                                                                    "table-border-left-bold":
                                                                                      mailingIndex ===
                                                                                      0
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          _vm.engagementMetricsData.datasets
                                                                                            .find(
                                                                                              function(
                                                                                                x
                                                                                              ) {
                                                                                                return (
                                                                                                  x.label ===
                                                                                                  mailing.name +
                                                                                                    " - " +
                                                                                                    metric
                                                                                                )
                                                                                              }
                                                                                            )
                                                                                            .data.reduce(
                                                                                              function(
                                                                                                prev,
                                                                                                next
                                                                                              ) {
                                                                                                return (
                                                                                                  prev +
                                                                                                  next
                                                                                                )
                                                                                              }
                                                                                            )
                                                                                        )
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          _vm
                                                                            .results
                                                                            .mailingsMetaData
                                                                            .length >
                                                                          1
                                                                            ? _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    "tt-" +
                                                                                    metric,
                                                                                  staticClass:
                                                                                    "text-right table-border-top-bold font-weight-bold table-border-left eng-summ-table-head-total"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          _vm.getTotalTableForEngagement(
                                                                                            metric
                                                                                          )
                                                                                        )
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      }
                                                                    )
                                                                  ],
                                                                  2
                                                                ),
                                                                _c(
                                                                  "tr",
                                                                  [
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-left primary--text font-weight-bold text-subtitle-2 eng-summ-table-th",
                                                                        style: {
                                                                          color:
                                                                            "#6d2c8a"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              %\n                            "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm.results
                                                                      .mailingsMetaData
                                                                      .length >
                                                                      1 &&
                                                                    _vm.pdfMenu
                                                                      ? [
                                                                          _vm._l(
                                                                            _vm.engagementMetricsComputed,
                                                                            function(
                                                                              metric
                                                                            ) {
                                                                              return [
                                                                                _vm._l(
                                                                                  _vm
                                                                                    .results
                                                                                    .mailingsMetaData,
                                                                                  function(
                                                                                    mailing,
                                                                                    mailingIndex
                                                                                  ) {
                                                                                    return _c(
                                                                                      "td",
                                                                                      {
                                                                                        key:
                                                                                          "%-" +
                                                                                          metric +
                                                                                          "-" +
                                                                                          mailingIndex,
                                                                                        staticClass:
                                                                                          "text-right font-weight-bold eng-summ-table-td-total",
                                                                                        class: {
                                                                                          "table-border-left-bold":
                                                                                            mailingIndex ===
                                                                                            0
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "percent"
                                                                                              )(
                                                                                                _vm.getEngagementPercent(
                                                                                                  _vm
                                                                                                    .engagementMetricsData
                                                                                                    .datasets,
                                                                                                  mailing.name +
                                                                                                    " - " +
                                                                                                    metric,
                                                                                                  _vm
                                                                                                    .results
                                                                                                    .mailingData
                                                                                                    .engagementSummary[
                                                                                                    mailingIndex
                                                                                                  ]
                                                                                                    .deliveredMessages
                                                                                                ),
                                                                                                0
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                _vm
                                                                                  .results
                                                                                  .mailingsMetaData
                                                                                  .length >
                                                                                1
                                                                                  ? _c(
                                                                                      "td",
                                                                                      {
                                                                                        key:
                                                                                          "tt-" +
                                                                                          metric,
                                                                                        staticClass:
                                                                                          "text-right font-weight-bold table-border-left eng-summ-table-bottom-total"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "percent"
                                                                                              )(
                                                                                                _vm.getTotalTableForEngagementPercent(
                                                                                                  metric
                                                                                                ),
                                                                                                0
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e()
                                                                              ]
                                                                            }
                                                                          )
                                                                        ]
                                                                      : [
                                                                          _vm._l(
                                                                            _vm.engagementMetricsComputed,
                                                                            function(
                                                                              metric
                                                                            ) {
                                                                              return [
                                                                                _vm._l(
                                                                                  _vm
                                                                                    .results
                                                                                    .mailingsMetaData,
                                                                                  function(
                                                                                    mailing,
                                                                                    mailingIndex
                                                                                  ) {
                                                                                    return _c(
                                                                                      "td",
                                                                                      {
                                                                                        key:
                                                                                          "%-" +
                                                                                          metric +
                                                                                          "-" +
                                                                                          mailingIndex,
                                                                                        staticClass:
                                                                                          "text-right font-weight-bold",
                                                                                        class: {
                                                                                          "table-border-left-bold":
                                                                                            mailingIndex ===
                                                                                            0
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "percent"
                                                                                              )(
                                                                                                _vm.getEngagementPercent(
                                                                                                  _vm
                                                                                                    .engagementMetricsData
                                                                                                    .datasets,
                                                                                                  mailing.name +
                                                                                                    " - " +
                                                                                                    metric,
                                                                                                  _vm
                                                                                                    .results
                                                                                                    .mailingData
                                                                                                    .engagementSummary[
                                                                                                    mailingIndex
                                                                                                  ]
                                                                                                    .deliveredMessages
                                                                                                ),
                                                                                                2
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                _vm
                                                                                  .results
                                                                                  .mailingsMetaData
                                                                                  .length >
                                                                                1
                                                                                  ? _c(
                                                                                      "td",
                                                                                      {
                                                                                        key:
                                                                                          "tt-" +
                                                                                          metric,
                                                                                        staticClass:
                                                                                          "text-right font-weight-bold table-border-left"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "percent"
                                                                                              )(
                                                                                                _vm.getTotalTableForEngagementPercent(
                                                                                                  metric
                                                                                                ),
                                                                                                2
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e()
                                                                              ]
                                                                            }
                                                                          )
                                                                        ]
                                                                  ],
                                                                  2
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    275823472
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider", { staticClass: "my-4" }),
                                _c("div", {
                                  staticClass: "html2pdf__page-break"
                                }),
                                _c(
                                  "section",
                                  { staticClass: "title-bar" },
                                  [
                                    _c(
                                      "v-card-subtitle",
                                      {
                                        staticClass:
                                          "grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text",
                                        staticStyle: { display: "flex" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Reading Engagement\n                "
                                        ),
                                        _c("v-spacer"),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !_vm.pdfMenu,
                                                                expression:
                                                                  "!pdfMenu"
                                                              }
                                                            ],
                                                            attrs: {
                                                              icon: "",
                                                              color: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.$_downloadFileMixin_downloadReportChart(
                                                                  _vm.results
                                                                    .readingEnvironmentsChart,
                                                                  "%",
                                                                  _vm.results
                                                                    .campaignMetaData
                                                                    .campaignNowwOrderNumber +
                                                                    "-" +
                                                                    _vm.results.campaignMetaData.campaignName.replace(
                                                                      / /g,
                                                                      ""
                                                                    ) +
                                                                    "-ReadingEnvironments"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          onTooltip
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "green lighten-3"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        mdi-file-download\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1924125755
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Download Reading Environments"
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !_vm.pdfMenu,
                                                                expression:
                                                                  "!pdfMenu"
                                                              }
                                                            ],
                                                            attrs: {
                                                              icon: "",
                                                              color: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.$_downloadFileMixin_downloadReportChart(
                                                                  _vm.results
                                                                    .emailClientDeviceSummary,
                                                                  "%",
                                                                  _vm.results
                                                                    .campaignMetaData
                                                                    .campaignNowwOrderNumber +
                                                                    "-" +
                                                                    _vm.results.campaignMetaData.campaignName.replace(
                                                                      / /g,
                                                                      ""
                                                                    ) +
                                                                    "-EmailClientsUsed"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          onTooltip
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "green lighten-3"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        mdi-file-download\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              221218679
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Download Email Clients Used"
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !_vm.pdfMenu,
                                                                expression:
                                                                  "!pdfMenu"
                                                              }
                                                            ],
                                                            attrs: {
                                                              icon: "",
                                                              color: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.$_downloadFileMixin_downloadReportChart(
                                                                  _vm.results
                                                                    .viewingEngagementTotalSummary,
                                                                  "%",
                                                                  _vm.results
                                                                    .campaignMetaData
                                                                    .campaignNowwOrderNumber +
                                                                    "-" +
                                                                    _vm.results.campaignMetaData.campaignName.replace(
                                                                      / /g,
                                                                      ""
                                                                    ) +
                                                                    "-TimeSpentViewing"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          onTooltip
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "green lighten-3"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        mdi-file-download\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2751021261
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Download Time Spent Viewing"
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm.results.mailingsMetaData.length > 1
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var onTooltip = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value: !_vm.pdfMenu,
                                                                    expression:
                                                                      "!pdfMenu"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  icon: "",
                                                                  color: "white"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.$_downloadFileMixin_downloadReportTable(
                                                                      "readingEngagement",
                                                                      _vm
                                                                        .results
                                                                        .campaignMetaData
                                                                        .campaignNowwOrderNumber +
                                                                        "-" +
                                                                        _vm.results.campaignMetaData.campaignName.replace(
                                                                          / /g,
                                                                          ""
                                                                        ) +
                                                                        "-TimeSpentViewingTable"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              onTooltip
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "green lighten-3"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        mdi-file-download\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  582298765
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Download Time Spent Viewing Table"
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.showAgeData
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var onTooltip = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value: !_vm.pdfMenu,
                                                                    expression:
                                                                      "!pdfMenu"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  icon: "",
                                                                  color: "white"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.$_downloadFileMixin_downloadReportChart(
                                                                      _vm
                                                                        .results
                                                                        .ageEngagementChart,
                                                                      "%",
                                                                      _vm
                                                                        .results
                                                                        .campaignMetaData
                                                                        .campaignNowwOrderNumber +
                                                                        "-" +
                                                                        _vm.results.campaignMetaData.campaignName.replace(
                                                                          / /g,
                                                                          ""
                                                                        ) +
                                                                        "-AgeEngagement",
                                                                      true
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              onTooltip
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "green lighten-3"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        mdi-file-download\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3867147777
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Download Age Engagement"
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "section",
                                  { staticClass: "reading-eng" },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "pa-2 ma-2 dist-eng-charts"
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { "no-gutters": "" } },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c("line-chart", {
                                                  staticClass: "pb-2",
                                                  attrs: {
                                                    "chart-data":
                                                      _vm.results.mailingData
                                                        .viewingEngagementSummary,
                                                    options: _vm.getChartOptionsLinePercent(
                                                      "Total Viewing Engagement",
                                                      "% of Open Audience",
                                                      "Time spent viewing (seconds)"
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider", { staticClass: "my-4" }),
                                _c("div", {
                                  staticClass: "html2pdf__page-break"
                                }),
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          md: _vm.pdfMenu ? "6" : "4"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "pa-2 ma-2 dist-eng-charts"
                                          },
                                          [
                                            _c("bar-chart", {
                                              attrs: {
                                                "chart-data":
                                                  _vm.results
                                                    .readingEnvironmentsChart,
                                                options: _vm.getChartOptionsBarPercent(
                                                  "Reading Environments",
                                                  "% of Gross Opens",
                                                  "Environment"
                                                ),
                                                height: 350
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          md: _vm.pdfMenu ? "6" : "4"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          { staticClass: "pa-2 ma-2" },
                                          [
                                            _c("bar-chart", {
                                              attrs: {
                                                "chart-data":
                                                  _vm.results
                                                    .emailClientDeviceSummary,
                                                options: _vm.getChartOptionsBarPercent(
                                                  "Email Clients Used",
                                                  "% of Gross Opens",
                                                  "Email Client"
                                                ),
                                                height: 350
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          md: _vm.pdfMenu ? "6" : "4"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "pa-2 ma-2 dist-eng-charts",
                                            style: [
                                              _vm.pdfMenu
                                                ? { display: "inline-table" }
                                                : ""
                                            ]
                                          },
                                          [
                                            _c("bar-chart", {
                                              attrs: {
                                                "chart-data":
                                                  _vm.results
                                                    .viewingEngagementTotalSummary,
                                                options: _vm.getChartOptionsBarPercent(
                                                  "Time Spent Viewing",
                                                  "% of Gross Opens",
                                                  "Read Duration"
                                                ),
                                                height:
                                                  _vm.results.mailingsMetaData
                                                    .length > 1
                                                    ? 218
                                                    : 350
                                              }
                                            }),
                                            _vm.results.mailingsMetaData
                                              .length > 1
                                              ? _c("v-simple-table", {
                                                  staticClass: "px-8",
                                                  class: {
                                                    "pt-4": _vm.pdfMenu
                                                  },
                                                  staticStyle: { flex: "auto" },
                                                  attrs: {
                                                    id: "readingEngagement",
                                                    dense: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function() {
                                                          return [
                                                            _c("thead", [
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c("th", {
                                                                    staticClass:
                                                                      "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold reading-eng-th-text"
                                                                  }),
                                                                  _vm._l(
                                                                    _vm.results
                                                                      .mailingData
                                                                      .litmusReadTable,
                                                                    function(
                                                                      mailing,
                                                                      i
                                                                    ) {
                                                                      return _c(
                                                                        "th",
                                                                        {
                                                                          key: i,
                                                                          staticClass:
                                                                            "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold reading-eng-th-text",
                                                                          class: {
                                                                            "table-border-left-bold":
                                                                              i ===
                                                                              0
                                                                          },
                                                                          style: {
                                                                            color:
                                                                              "#6d2c8a"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .results
                                                                                  .mailingsMetaData[
                                                                                  i
                                                                                ]
                                                                                  .name
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticClass:
                                                                        "text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold reading-eng-th-text",
                                                                      style: {
                                                                        color:
                                                                          "#6d2c8a"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            Total\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ]),
                                                            _c(
                                                              "tbody",
                                                              _vm._l(
                                                                _vm.readTimeMetrics,
                                                                function(
                                                                  metric,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "tr",
                                                                    {
                                                                      key: index
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left primary--text font-weight-bold text-subtitle-2 reading-eng-trtitle-text",
                                                                          style: {
                                                                            color:
                                                                              "#6d2c8a"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  _vm
                                                                                    .results
                                                                                    .viewingEngagementTotalSummary
                                                                                    .datasets[0]
                                                                                    .backgroundColor[
                                                                                    index
                                                                                  ]
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              mdi-square-rounded\n                            "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                metric.display
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._l(
                                                                        _vm
                                                                          .results
                                                                          .mailingData
                                                                          .litmusReadTable,
                                                                        function(
                                                                          litmusRow,
                                                                          litmusIndex
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "td",
                                                                              {
                                                                                key:
                                                                                  "" +
                                                                                  litmusIndex,
                                                                                staticClass:
                                                                                  "text-right reading-eng-td-row",
                                                                                class: {
                                                                                  "table-border-left-bold":
                                                                                    litmusIndex ===
                                                                                    0
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm._f(
                                                                                        "percent"
                                                                                      )(
                                                                                        litmusRow.find(
                                                                                          function(
                                                                                            x
                                                                                          ) {
                                                                                            return (
                                                                                              x.device ==
                                                                                              "Total"
                                                                                            )
                                                                                          }
                                                                                        )[
                                                                                          metric
                                                                                            .rate
                                                                                        ]
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      ),
                                                                      _vm._l(
                                                                        _vm
                                                                          .results
                                                                          .litmusReadTable,
                                                                        function(
                                                                          litmusRow,
                                                                          litmusIndex
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "td",
                                                                              {
                                                                                key:
                                                                                  "t-" +
                                                                                  litmusIndex,
                                                                                staticClass:
                                                                                  "text-right font-weight-bold table-border-left-bold reading-eng-td-total"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm._f(
                                                                                        "percent"
                                                                                      )(
                                                                                        litmusRow[
                                                                                          metric
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    2715540642
                                                  )
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm.showAgeData
                                  ? _c("div", {
                                      staticClass: "html2pdf__page-break"
                                    })
                                  : _vm._e(),
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _vm.showAgeData
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "pa-2 ma-2 dist-eng-charts"
                                              },
                                              [
                                                _c("bar-chart", {
                                                  staticClass: "pb-2",
                                                  attrs: {
                                                    "chart-data":
                                                      _vm.results
                                                        .ageEngagementChart,
                                                    options: _vm.getChartOptionsBarPercent2(
                                                      "Age Engagement",
                                                      "%",
                                                      "Age Range"
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c("v-divider", { staticClass: "my-4" })
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }